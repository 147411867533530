import React, { useState, useEffect } from "react";

import { gql, useMutation, useQuery } from "@apollo/client";

import { useSearchParams } from "react-router-dom";

const USER_GET = gql`
  query getUserById($uid: String!) {
    user(uid: $uid) {
        id
        uid
    }
  }
`;

const USER_UPDATE = gql`
  mutation ($userId: ID!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userErrors {
        message
      }
      user {
        name
      }
    }
  }
`;

function VerifyAccount() {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [userProfile, setUserProfile] = useState(null);

    const [searchParams] = useSearchParams();

    let uid = searchParams.get("uid");
    let id = searchParams.get("id");

    //GRAPHQL USER QUERY START
    const { data, loading } = useQuery(USER_GET, {
        variables: { uid: uid, id: id },
    });
    //GRAPHQL USER QUERY END

    //GRAPHQL USER CHANGE PASSWORD START
    const [
        verifyAccount
    ] = useMutation(USER_UPDATE);
    //GRAPHQL USER CHANGE PASSWORD END

    useEffect(() => {
        let userProfile = null;
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);
        }
    }, [data, loading]);

    useEffect(() => {
        if (userProfile) {
            verifyAccount({
                variables: {
                    userId: userProfile.uid,
                    user: {
                        isVerified: true,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile])

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
            setSuccessMessage("");
        }, 10000);
    }, [errorMessage, successMessage]);

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <div>
                <p className="text-xl text-green-500 text-center">{successMessage}</p>
                <p className="text-xl text-red-500 font-semibold">{errorMessage}</p>
            </div>
            <div className="bg-white rounded-md p-4 shadow-md">
                {userProfile && userProfile.uid && !loading ? (
                    <div className="grid grid-cols-1">
                        <h1 className="text-2xl text-center uppercase text-gray-600">Verify Your Account</h1>
                        <hr />
                        <p>Your account has been verified. Please Login to your account.</p>
                    </div>) : (
                    <div>Please wait.....</div>
                )
                }
            </div>

        </div>
    );
}

export default VerifyAccount;
