import React, { useEffect, useState } from 'react';

function GroupSelector({ handleWAAllGroups, setWaGroups, createNewGroup }) {
    const [selectedOption, setSelectedOption] = useState(''); // Default to 'existing'
    const [newGroupName, setNewGroupName] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);

        if (event.target.value === "existing") {
            handleWAAllGroups()
        } else {
            setWaGroups([])
        }
    };

    const handleInputChange = (event) => {
        setNewGroupName(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        createNewGroup(newGroupName)
        // Logic to handle the new group creation
        console.log('New Group Created:', newGroupName);
    };



    return (
        <div className=''>
            {/* Radio buttons */}
            <div className="mb-4 grid grid-cols-2">
                <label className="mr-4">
                    <input
                        type="radio"
                        value="existing"
                        checked={selectedOption === 'existing'}
                        onChange={handleOptionChange}
                        className="mr-2"
                    />
                    Existing Group
                </label>

                <label>
                    <input
                        type="radio"
                        value="new"
                        checked={selectedOption === 'new'}
                        onChange={handleOptionChange}
                        className="mr-2"
                    />
                    New Group
                </label>
            </div>

            {/* Form for new group, visible only if "New Group" is selected */}
            {selectedOption === 'new' && (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="newGroupName" className="block font-medium mb-1">
                            New Group
                        </label>
                        <input
                            type="text"
                            id="newGroupName"
                            value={newGroupName}
                            onChange={handleInputChange}
                            placeholder="Enter group name"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                    >
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
}

export default GroupSelector;
