import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import Emojis from "../../util/Emojis";
import { gql, useQuery } from "@apollo/client";
import CronStringGenerator from "../../util/CronStringGenerator";
import Container from "../../components/Container";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

function SingleMessage() {
  const [devices, setDevices] = useState([]);


  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  //GRAPHQL DEVICE QUERY START
  const { refetch, data, loading } = useQuery(DEVICE_GET);

  const [message, setMessage] = useState({});
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isScheduleProcess, setIsScheduleProcess] = useState(false);

  const selectedDeviceRef = useRef([]);

  const cursorRef = useRef("");

  const [buttonValue, setButtonValue] = useState({ templateType: "None", countryCode: "91" });

  const [isClicked, setIsClicked] = useState(false);
  const [isScheduleClicked, setIsScheduleClicked] = useState(false);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const phoneRef = useRef("");
  const messageRef = useRef("");
  const dateTimeRef = useRef({});
  const quillRef = useRef(null);
  const fileRef = useRef("");

  function formatWhatsAppMessage(message) {
    // Replace <em> tags with WhatsApp italic formatting
    message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

    // Replace <strong> tags with WhatsApp bold formatting, allowing optional spaces around the text
    message = message.replace(/<strong>\s*(.*?)\s*<\/strong>/g, '*$1*');

    // Replace <u> tags (underline) with emphasis (using _ for emphasis since underline isn't supported)
    message = message.replace(/<u>(.*?)<\/u>/g, '_$1_');

    // Ensure <p> tags add new lines
    message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
    message = message.replace(/<p>/g, ''); // Remove opening <p> tags

    // Convert ordered list <ol> into plain numbered list
    message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
      let formattedList = '\n'; // Newline before the list
      let counter = 1;
      // Process each <li> item
      listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
        formattedList += `${counter}. ${listItem}\n`; // Add numbering
        counter++;
      });
      return formattedList; // Return formatted list
    });

    // Convert unordered list <ul> into plain bullet list
    message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
      let formattedList = '\n'; // Newline before the list
      // Process each <li> item
      listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
        formattedList += `• ${listItem}\n`; // Add bullet points
      });
      return formattedList; // Return formatted list
    });

    // Remove any other remaining HTML tags
    message = message.replace(/<[^>]*>/g, '');

    // Handle adjacent formatting symbols and ensure no spaces are lost
    message = message.replace(/_([\s\S]+?)_|\*([\s\S]+?)\*/g, function (match, italic, bold) {
      if (italic) return `_${italic}_`;
      if (bold) return `*${bold}*`;
      return match;
    });

    return message.trim(); // Trim any trailing spaces or newlines
  }

  const handleMessage = (e) => {
    setMessage((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const sendScheduleFileMessage = () => {
    const apiURL = `https://mrkgroups.com/schedule/addFileMessage`;
    const formData = new FormData();

    formData.append("id", userInfo.uid);
    formData.append("uid", userInfo.uid);
    formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
    formData.append("deviceName", selectedDeviceRef.current[0]);
    formData.append("file", selectedFile);
    formData.append("message", formatWhatsAppMessage(message.message));
    formData.append("dateTime", dateTimeRef.current.value);
    formData.append("phones", message.phone.replace(/[^0-9]|\s+/g, "").trim());

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          setStatus(msgResp);
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("You are not authorized to make this request");
      });
  };

  const sendScheduleMessage = () => {
    setIsScheduleProcess(true);

    let apiURL = `https://mrkgroups.com/schedule/addMessage`;

    if (
      message.phone &&
      message.phone !== "" &&
      message.phone !== ""
      // message.phone.toString().length === 12
    ) {
      if (message.message && message.message !== "") {
        if (isSelected) {
          sendScheduleFileMessage();
        } else {
          var data = JSON.stringify({
            id: userInfo.uid,
            phones: message.phone.replace(/[^0-9]|\s+/g, "").trim(),
            message: formatWhatsAppMessage(message.message),
            dateTime: dateTimeRef.current.value,
            cronString: CronStringGenerator(dateTimeRef.current.value),
            uid: userInfo.uid,
            deviceName: selectedDeviceRef.current[0],
            file: ""
          });

          var config = {
            method: "POST",
            url: apiURL,
            headers: {
              "Content-type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              let msgResp = response.data.message;
              let msgStatus = response.data.status;

              if (msgStatus !== "error") {

                setStatus(msgResp);

              } else {
                setError(msgResp);
              }
            })
            .catch(function (error) {
              setError("You are not authorized to make this request");
            });
        }
      } else {
        setError("Message is empty");
      }
    } else {
      setError("Phone is not valid");
    }
  }

  const sendFileMessage = (deviceName) => {
    const apiURL = `https://mrkgroups.com/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("message", formatWhatsAppMessage(message.message));
    formData.append("phone", message.phone.replace(/[^0-9]|\s+/g, "").trim());

    fetch(apiURL, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        let msgResp = result.message;
        let msgStatus = result.status;

        if (msgStatus !== "error") {
          setStatus(msgResp);
        } else {
          setError(msgResp);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("You are not authorized to make this request");
      });
  };

  const sendMessage = () => {
    setIsClicked(true);

    let indexRef = 0;
    let apiURL = `https://mrkgroups.com/chat/sendMessage`;

    if (selectedDeviceRef.current.length === 0) {
      setError("Please select atleast one device");
      return
    }

    if (
      message.phone &&
      message.phone !== ""
      // message.phone.toString().length === 12
    ) {
      if (message.message && message.message !== "") {
        if (isSelected) {
          sendFileMessage(selectedDeviceRef.current[indexRef]);
        } else {

          const sendMsg = (deviceName) => {
            let data = JSON.stringify({
              "id": userInfo.uid,
              "name": deviceName,
              "phone": message.phone.replace(/[^0-9]|\s+/g, "").trim(),
              "message": formatWhatsAppMessage(message.message)
            });

            var config = {
              method: "POST",
              url: apiURL,
              headers: {
                "Content-type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                let msgResp = response.data.message;
                let msgStatus = response.data.status;

                if (msgStatus !== "error") {

                  setStatus(msgResp);
                } else {
                  setError(msgResp);
                }
              })
              .catch(function (error) {
                setError("You are not authorized to make this request");
              });
          }

          sendMsg(selectedDeviceRef.current[indexRef]);
        }
      } else {
        setError("Message is empty");
      }
    } else {
      setError("Phone is not valid");
    }
  };

  const handleSelectedDevice = (e) => {

    if (e.target.value) {
      selectedDeviceRef.current.push(e.target.value)
    } else {
      selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
    }

    if (selectedDeviceRef.current.length > 0) {
      setIsDeviceSelected(true)
    } else {
      setIsDeviceSelected(false)
    }
  }

  const resetAll = () => {

    setMessage(null);
    setStatus(null);
    setError(null);
    setSelectedFile(null);
    setIsSelected(false);
    setIsScheduleProcess(false);
    setIsClicked(false);
    setIsScheduleClicked(false);
    phoneRef.current.value = null;
    fileRef.current.value = null
    dateTimeRef.current.value = {};
  }

  //FETCH ALL ADDED DEVICES
  useEffect(() => {
    let devicesByUID = null;
    if (data) {
      devicesByUID = data.devicesByUID;
      setDevices(devicesByUID);
    }
  }, [data, loading]);

  useEffect(() => {
    if (userInfo) {
      refetch({ uid: userInfo.uid })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      error !== "" ||
      status !== ""
    ) {

      setTimeout(() => {
        resetAll()
      }, 5000);
    }
  }, [error, status]);


  const handleQuillChange = (value) => {
    setMessage((prevData) => {
      return { ...prevData, ["message"]: value };
    });
  };

  // Function to track and update cursor position using Quill's API
  const handleSelectionChange = useCallback(() => {
    const editor = quillRef.current.getEditor(); // Get Quill editor instance
    const selection = editor.getSelection();
    if (selection) {
      cursorRef.current = selection.index; // Update cursor position
    }
  }, []);

  // Effect to track selection changes in Quill
  useEffect(() => {
    const editor = quillRef.current.getEditor(); // Get Quill editor instance
    editor.on('selection-change', handleSelectionChange); // Quill event listener for selection change

    return () => {
      editor.off('selection-change', handleSelectionChange); // Cleanup event listener
    };
  }, [handleSelectionChange]);

  // Insert emoji at cursor position
  const insertEmoji = (emoji) => {
    const editor = quillRef.current.getEditor(); // Get Quill editor instance
    const cursorPosition = cursorRef.current; // Get current cursor position

    if (cursorPosition !== undefined) {
      editor.insertText(cursorPosition, emoji); // Insert emoji at the cursor position
    }
  };

  return (
    <div className="flex w-screen h-screen ">
      <Sidebar />
      <Container>
        <div className="grid grid-cols-12 gap-2  px-4 pt-8 pl-8">
          <div className="col-span-9">
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-start gap-2">
                <div className="">
                  <p className="text-sm font-semibold">CONNECTED DEVICE : </p>
                </div>
                <div className="flex justify-start items-center" >
                  <select onChange={handleSelectedDevice}>
                    <option name="" value="" key="select device">Select device</option>
                    {
                      devices && devices.map((device) => {
                        return (
                          <option name={device.deviceName} key={device.deviceName} value={device.deviceName} >{device.deviceName}</option>
                        )
                      }
                      )
                    }
                  </select>
                </div>
              </div>
              <div
                action=""
                method="post"
                className="flex w-10/12 flex-col space-y-4"
              >
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  className="w-full rounded-md p-2 shadow-md"
                  onChange={handleMessage}
                  ref={phoneRef}
                  placeholder="enter receiver phone no. with country code"
                />

                <ReactQuill
                  ref={quillRef} // Reference to the Quill editor
                  name="message"
                  id="message"
                  className="w-full rounded-md p-2"
                  onChange={handleQuillChange}
                  value={message?.message}
                  disabled={isClicked}
                  placeholder="Message"
                  theme="snow"
                />

                <label htmlFor="file" className="text-xs  text-gray-800 uppercase font-semibold">
                  Attach File:
                </label>
                <input
                  type="file"
                  accept=".txt, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  name="file"
                  id="file"
                  onChange={changeHandler}
                  ref={fileRef}
                />

                <div>
                  <p className="text-xs font-semibold text-green-600 uppercase pb-2 ">About File</p>
                  <div>
                    <p>
                      Filename: {selectedFile.name ? selectedFile.name : ""}
                    </p>
                    <p>
                      Filetype: {selectedFile.type ? selectedFile.type : ""}
                    </p>
                    <p>
                      Size in bytes:{" "}
                      {selectedFile.size ? selectedFile.size : ""}
                    </p>
                    <p>
                      lastModifiedDate:{" "}
                      {selectedFile.lastModifiedDate
                        ? selectedFile.lastModifiedDate.toLocaleDateString()
                        : ""}
                    </p>
                  </div>

                </div>
                {isScheduleClicked && (
                  <div className="my-8">
                    <label htmlFor="dateTime" className="text-sm font-semibold text-gray-800 uppercase mb-2">
                      Schedule DateTime:
                    </label>
                    <input
                      type="datetime-local"
                      name="dateTime"
                      id="dateTime"
                      className="w-full rounded-md px-2 py-1"
                      onChange={handleMessage}
                      ref={dateTimeRef}
                    />
                  </div>
                )}
                <div className="my-2">
                  {status !== "" ? (
                    <p className="text-center text-xl tracking-normal text-green-500 ">
                      {status}
                    </p>
                  ) : error !== "" ? (
                    <p className="text-center text-xl tracking-normal text-red-500">
                      {error}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="p-2 text-right">
                  <button
                    className="rounded-md bg-orange-600 py-1 px-4 text-sm text-white"
                    onClick={sendMessage}
                    disabled={isClicked || isScheduleClicked || !isDeviceSelected}
                  >
                    {!isDeviceSelected ? "Select device" : isClicked ? "Sending..." : "Send Now"}
                  </button>
                  {!isScheduleClicked && (<button
                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
                    onClick={() => setIsScheduleClicked(true)}
                  >
                    Schedule
                  </button>)}
                  {isScheduleClicked && !isScheduleProcess && (<button
                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
                    disabled={isClicked || !isScheduleClicked || !isDeviceSelected}
                    onClick={() => sendScheduleMessage()}
                  >
                    Set Schedule
                  </button>)}
                  {isScheduleClicked && isScheduleProcess && (<button
                    className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
                  >
                    Please Wait...
                  </button>)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SingleMessage;
