import axios from "axios";
import readXlsxFile from "read-excel-file";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import Emojis from "../../util/Emojis";
import Container from "../../components/Container";
import { DownloadCloud } from "lucide-react";
import CronStringGenerator from "../../util/CronStringGenerator";
import { gql, useQuery } from "@apollo/client";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const GET_TEMPLATES = gql`
    query($uid: String!){
        templateByUID(uid:$uid) {
            id,
            templateName,
            body
        }
    }
`
const MultiMessage = () => {
    const { refetch, data, loading } = useQuery(DEVICE_GET);
    const [devices, setDevices] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const { data: templateData, loading: templateLoading } = useQuery(GET_TEMPLATES, {
        variables: {
            uid: userInfo.uid
        }
    });
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFileImportSelected, setIsFileImportSelected] = useState(false);
    const fileImportData = useRef([]);
    const [isScheduleProcess, setIsScheduleProcess] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
    const [templates, setTemplates] = useState(null);

    const [sentMessage, setSentMessage] = useState(0);
    const [totalMessage, setTotalMessage] = useState(0);

    const cursorRef = useRef(0);
    const dateTimeRef = useRef({});

    const [isClicked, setIsClicked] = useState(false);

    const phoneRef = useRef();
    const selectedTemplateRef = useRef("");
    const messageRef = useRef();
    const fileRef = useRef();
    const phonesFile = useRef();

    const quillRef = useRef(null);

    const selectedDeviceRef = useRef([]);


    const [fileName, setFileName] = useState('Choose Excel File');

    const sendScheduleFileMessage = () => {
        const apiURL = `https://mrkgroups.com/schedule/addFileMessage`;
        const formData = new FormData();

        formData.append("id", userInfo.uid);
        formData.append("uid", userInfo.uid);
        formData.append("cronString", CronStringGenerator(dateTimeRef.current.value));
        formData.append("deviceName", selectedDeviceRef.current[0]);
        formData.append("file", selectedFile);
        formData.append("message", message.message);
        formData.append("dateTime", dateTimeRef.current.value);
        formData.append("phones", message.phone.replace(/[^0-9]|\s+/g, "").trim());

        fetch(apiURL, {
            method: "POST",
            headers: {},
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                let msgResp = result.message;
                let msgStatus = result.status;

                if (msgStatus !== "error") {
                    setMessage(msgResp);
                } else {
                    setError(msgResp);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setError("You are not authorized to make this request");
            });
    };

    const sendScheduleMessage = () => {
        setIsScheduleProcess(true);

        let apiURL = `https://mrkgroups.com/schedule/addMessage`;

        if (
            message.phone &&
            message.phone !== "" &&
            message.phone !== ""
            // message.phone.toString().length === 12
        ) {
            if (message.message && message.message !== "") {
                if (isSelected) {
                    sendScheduleFileMessage();
                } else {
                    var data = JSON.stringify({
                        id: userInfo.uid,
                        phones: message.phone.replace(/[^0-9]|\s+/g, "").trim(),
                        message: message.message,
                        dateTime: dateTimeRef.current.value,
                        cronString: CronStringGenerator(dateTimeRef.current.value),
                        uid: userInfo.uid,
                        deviceName: selectedDeviceRef.current[0],
                        file: ""
                    });

                    var config = {
                        method: "POST",
                        url: apiURL,
                        headers: {
                            "Content-type": "application/json",
                        },
                        data: data,
                    };

                    axios(config)
                        .then(function (response) {
                            let msgResp = response.data.message;
                            let msgStatus = response.data.status;

                            if (msgStatus !== "error") {

                                setMessage(msgResp);

                            } else {
                                setError(msgResp);
                            }
                        })
                        .catch(function (error) {
                            setError("You are not authorized to make this request");
                        });
                }
            } else {
                setError("Message is empty");
            }
        } else {
            setError("Phone is not valid");
        }
    }

    const handleSelectedDevice = (e) => {

        if (e.target.value) {
            selectedDeviceRef.current.push(e.target.value)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)
        } else {
            setIsDeviceSelected(false)
        }
    }

    const handleSelectTemplate = (e) => {
        if (e.target.value) {
            setMessage((prevData) => {
                return { ...prevData, ["message"]: e.target.value };
            });
        }
    }

    const handleQuillChange = (value) => {

        setMessage((prevData) => {
            return { ...prevData, ["message"]: value };
        });
    };

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const handleExcelFile = async (e) => {

        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name); // Update the label to show the selected file name
        }

        const map = {
            Name: "Name",
            Phone: "Phone",
            Message: "Message",
        };

        let file = phonesFile.current.files[0];

        let phones = [];

        await readXlsxFile(file, { map }).then(({ rows }) => {
            rows.forEach((row) => {
                phones.push(row.Phone.toString().trim());
                fileImportData.current.push(row);
            });

            if (fileImportData.current.length > 0) {
                setIsFileImportSelected(true);
            }
        });

        phoneRef.current.value = phones.join(",");
        //SET Remain Msg to be send
        setTotalMessage(Number(phones.length));
    };

    const checkInvalidNumbers = (numbers) => {
        let invNumber = numbers.filter((number) => number.length !== 12);
        return invNumber;
    };

    const resetAllParameters = () => {
        setStatus(null);
        setError(null);
        setIsClicked(false);
        setSelectedFile(null);
        setIsSelected(false);
        setTotalMessage(0);
        setSentMessage(0);
        setMessage(null);
        setMessage(null);
        setTemplates([])
        setIsScheduleProcess(false);
        setIsScheduleClicked(false);
        setFileName('Choose Excel File')
        fileRef.current.value = null;
        phonesFile.current.value = null;
        phoneRef.current.value = null;
    };

    let i = 0;
    let phones = [];
    let apiURL = "";

    function formatWhatsAppMessage(htmlMessage, name) {
        // Replace {{name}} placeholder with the actual name
        let message = htmlMessage.replace('{{name}}', name);

        // Replace <em> tags with WhatsApp italic formatting
        message = message.replace(/<em>(.*?)<\/em>/g, '_$1_');

        // Replace <strong> tags with WhatsApp bold formatting
        message = message.replace(/<strong>(.*?)<\/strong>/g, '*$1*');

        // Replace <u> tags (underline) with plain text or you can use another formatting
        message = message.replace(/<u>(.*?)<\/u>/g, '_$1_'); // Using _ for emphasis since underline isn't supported

        // Ensure <p> tags add new lines
        message = message.replace(/<\/p>/g, '\n'); // Add a newline after each paragraph
        message = message.replace(/<p>/g, ''); // Remove opening <p> tags

        // Convert ordered list <ol> into plain numbered list with a newline before it
        message = message.replace(/<ol>(.*?)<\/ol>/gs, function (match, listItems) {
            let formattedList = '\n'; // Add newline before the list
            let counter = 1;
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `${counter}. ${listItem}\n`; // Add numbering
                counter++;
            });
            return formattedList;
        });

        // Convert unordered list <ul> into plain bullet list with a newline before it
        message = message.replace(/<ul>(.*?)<\/ul>/gs, function (match, listItems) {
            let formattedList = '\n'; // Add newline before the list
            listItems.replace(/<li>(.*?)<\/li>/g, function (match, listItem) {
                formattedList += `• ${listItem}\n`; // Add bullet points
            });
            return formattedList;
        });

        // Remove any other remaining HTML tags
        message = message.replace(/<[^>]*>/g, '');

        return message.trim(); // Trim any trailing newlines or spaces
    }

    const formatedMessage = (msg, name) => {
        let fMessage = msg.replace("{{name}}", name);
        return formatWhatsAppMessage(fMessage)
    };

    const sendFormatedMessage = (phone, fmsg) => {
        if (phone && phone !== "" && phone.toString().length === 12) {
            var data = JSON.stringify({
                id: userInfo.uid,
                phone: phone.toString().trim(),
                message: fmsg,
                name: selectedDeviceRef.current[0],
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFormatedFileMessage = (phone, fmsg) => {
        if (phone && phone !== "" && phone.toString().length === 12) {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("file", selectedFile);
            formData.append("message", fmsg);
            formData.append("phone", phone.replace(/[^0-9]|\s+/g, "").trim());
            formData.append("name", selectedDeviceRef.current[0]);

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedFileMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFileMessageMulti = (phone) => {
        if (phone && phone !== "" && phone.toString().length === 12) {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("file", selectedFile);
            formData.append("message", message.message);
            formData.append("deviceName", selectedDeviceRef.current[0]);
            formData.append("phone", phone.replace(/[^0-9]|\s+/g, "").trim());

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;
                        //Set Msg sent count
                        setSentMessage(i);
                        if (i < phones.length) {
                            sendFileMessageMulti(phones[i]);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFileMessage = (deviceName) => {
        apiURL = `https://mrkgroups.com/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;

        phones = phoneRef.current.value.split(",");
        setTotalMessage(Number(phones.length));

        if (phones.length > 0) {
            sendFileMessageMulti(phones[i]);
        }
    };

    const sendMessageMulti = (phone) => {
        if (phone && phone !== "" && phone.toString().length === 12) {
            var data = JSON.stringify({
                id: userInfo.uid,
                phone: phone.replace(/[^0-9]|\s+/g, "").trim(),
                message: message.message,
                name: selectedDeviceRef.current[0],
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;
                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            sendMessageMulti(phones[i]);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendMessage = () => {
        setIsClicked(true);

        apiURL = `https://mrkgroups.com/chat/sendmessage`;
        phones = phoneRef.current.value.split(",");
        setTotalMessage(Number(phones.length));

        if (selectedDeviceRef.current.length === 0) {
            setError("Please select atleast one device");
            return
        }

        let invalidNumbers = checkInvalidNumbers(phones);

        if (invalidNumbers.length === 0) {
            if (phoneRef.current.value) {
                if (phoneRef.current.value.length !== 0) {
                    if (isSelected) {
                        apiURL = `https://mrkgroups.com/chat/sendmessagefile/${userInfo.uid}`;

                        if (fileImportData.current.length > 0 && isFileImportSelected) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedFileMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            sendFileMessage(selectedDeviceRef.current[0]);
                        }
                    } else {
                        if (fileImportData.current.length > 0 && isFileImportSelected) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            sendMessageMulti(phones[i]);
                        }
                    }
                } else {
                    setError("Phone number is missing");
                }
            } else {
                setError("Phone is not valid");
            }
        } else {
            setError(`Check this number ${invalidNumbers.join(",")}`);
            invalidNumbers = [];
        }
    };

    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {

        if (templateData) {
            let data = templateData.templateByUID;

            setTemplates(data);
        }
    }, [templateData, templateLoading]);

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((status !== null || error !== null) && sentMessage === totalMessage) {
            setTimeout(() => {
                resetAllParameters();
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, status, sentMessage]);

    // Function to track and update cursor position using Quill's API
    const handleSelectionChange = useCallback(() => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        const selection = editor.getSelection();
        if (selection) {
            cursorRef.current = selection.index; // Update cursor position
        }
    }, []);

    // Effect to track selection changes in Quill
    useEffect(() => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        editor.on('selection-change', handleSelectionChange); // Quill event listener for selection change

        return () => {
            editor.off('selection-change', handleSelectionChange); // Cleanup event listener
        };
    }, [handleSelectionChange]);

    // Insert emoji at cursor position
    const insertEmoji = (emoji) => {
        const editor = quillRef.current.getEditor(); // Get Quill editor instance
        const cursorPosition = cursorRef.current; // Get current cursor position

        if (cursorPosition !== undefined) {
            editor.insertText(cursorPosition, emoji); // Insert emoji at the cursor position
        }
    };


    return (
        <div className="flex w-screen h-screen bg-white">
            <Sidebar />
            <Container>
                <div className="grid grid-cols-12 gap-2 px-4 pt-8 pl-8">
                    <div className="col-span-9">
                        <div className="flex w-10/12 flex-col space-y-4">
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <p className="text-sm font-semibold">CONNECTED DEVICE : </p>
                                </div>
                                <div>
                                    <select onChange={handleSelectedDevice}>
                                        <option name="" value="" key="select device">Select device</option>
                                        {
                                            devices && devices.map((device) => {
                                                return (
                                                    <option name={device.deviceName} key={device.deviceName} value={device.deviceName} >{device.deviceName}</option>
                                                )
                                            }
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <p className="text-sm font-semibold">SELECT TEMPLATE : </p>
                                </div>
                                <div>
                                    <select ref={selectedTemplateRef} onChange={handleSelectTemplate}>
                                        <option name="" value="" key="select device">Select Template</option>
                                        {
                                            templates && templates.map((template) => {
                                                return (
                                                    <option name={template.templateName} key={template.templateName} value={template.body} >{template.templateName}</option>
                                                )
                                            }
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                            <div
                                action=""
                                method="post"
                                className="flex w-10/12 flex-col space-y-4"
                            >
                                <div className="flex justify-start items-center">
                                    <p className="inline-block text-xs font-bold uppercase leading-8 tracking-wider text-gray-800">
                                        Download Sample Excel File
                                    </p>
                                    <a
                                        href="./assets/samples.xlsx"
                                        className="text-sm ml-4 h-8 w-8 rounded-full font-semibold leading-8"
                                    >
                                        <DownloadCloud />
                                    </a>
                                </div>

                                <div className="flex justify-between text-gray-800">
                                    <label
                                        htmlFor="phone"
                                        className="text-sm font-semibold text-gray-800"
                                    >
                                        Phone No:
                                    </label>

                                    <div>
                                        <input
                                            type="file"
                                            id="input"
                                            ref={phonesFile}
                                            onChange={handleExcelFile}
                                            accept=".xlsx"
                                            disabled={isClicked}
                                            style={{ display: 'none' }} // Hide the default file input
                                        />
                                        <label htmlFor="input" style={{ cursor: 'pointer', backgroundColor: '#007bff', color: 'white', padding: '8px', borderRadius: '5px' }}>
                                            {fileName}
                                        </label>
                                    </div>
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className=" w-full rounded-md p-2"
                                        onChange={handleMessage}
                                        ref={phoneRef}
                                        disabled={isClicked}
                                        placeholder="enter receiver phone no. with country code, seperate them by comma"
                                    />
                                </div>

                                <label
                                    htmlFor="message"
                                    className="text-sm font-semibold text-gray-800"
                                >

                                    <span className="text-sm  text-gray-800">
                                        Que : {totalMessage}
                                    </span>{" "}
                                    |{" "}
                                    <span className="text-sm  text-gray-800">
                                        Sent : {sentMessage}
                                    </span>
                                </label>

                                <ReactQuill
                                    ref={quillRef} // Reference to the Quill editor
                                    name="message"
                                    id="message"
                                    className="w-full rounded-md p-2"
                                    onChange={handleQuillChange}
                                    value={message?.message}
                                    disabled={isClicked}
                                    placeholder="Message"
                                    theme="snow"
                                />

                                <label htmlFor="file" className="text-sm font-semibold text-gray-800">
                                    Attach File:
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    accept=".txt, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    id="file"
                                    onChange={changeHandler}
                                    ref={fileRef}
                                    disabled={isClicked}
                                />
                                <p className="text-xs font-semibold text-green-600 uppercase pb-2 ">About File</p>
                                <div>
                                    <p>
                                        Filename: {selectedFile.name ? selectedFile.name : ""}
                                    </p>
                                    <p>
                                        Filetype: {selectedFile.type ? selectedFile.type : ""}
                                    </p>
                                    <p>
                                        Size in bytes:{" "}
                                        {selectedFile.size ? selectedFile.size : ""}
                                    </p>
                                    <p>
                                        lastModifiedDate:{" "}
                                        {selectedFile.lastModifiedDate
                                            ? selectedFile.lastModifiedDate.toLocaleDateString()
                                            : ""}
                                    </p>
                                </div>
                                {isScheduleClicked && (
                                    <div className="my-8">
                                        <label htmlFor="dateTime" className="text-sm font-semibold text-gray-800 uppercase mb-2">
                                            Schedule DateTime:
                                        </label>
                                        <input
                                            type="datetime-local"
                                            name="dateTime"
                                            id="dateTime"
                                            className="w-full rounded-md px-2 py-1"
                                            onChange={handleMessage}
                                            ref={dateTimeRef}
                                        />
                                    </div>
                                )}

                                <div className="p-2 text-right">
                                    <button
                                        className="rounded-md bg-orange-600 py-1 px-4 text-sm text-white"
                                        onClick={sendMessage}
                                        disabled={isClicked || isScheduleClicked || !isDeviceSelected}
                                    >
                                        {!isDeviceSelected ? "Select device" : isClicked ? "Sending..." : "Send Now"}
                                    </button>
                                    {!isScheduleClicked && (<button
                                        className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
                                        onClick={() => setIsScheduleClicked(true)}
                                    >
                                        Schedule
                                    </button>)}
                                    {isScheduleClicked && !isScheduleProcess && (<button
                                        className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
                                        disabled={isClicked || !isScheduleClicked || !isDeviceSelected}
                                        onClick={() => sendScheduleMessage()}
                                    >
                                        Set Schedule
                                    </button>)}
                                    {isScheduleClicked && isScheduleProcess && (<button
                                        className="rounded-md bg-blue-400 py-1 px-4 text-sm text-white ml-2"
                                    >
                                        Please Wait...
                                    </button>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3">
                        <Emojis getSelectedEmoji={(data) => insertEmoji(data)} />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default MultiMessage;
