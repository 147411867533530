import React, { useCallback, useEffect, useState } from 'react';
import { Add, Remove } from '@mui/icons-material';
import useGoogleSheet from '../hook/useGoogleSheet';
import useGoogleEventSheet from '../hook/useGoogleEventSheet';

// Component for selecting a sheet
function SheetSelector({ sheetNames, onSelect }) {
    return (
        <select onChange={(e) => onSelect(e.target.value)}>
            <option>Select a page</option>
            {sheetNames && sheetNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
            ))}
        </select>
    );
}

// Component for selecting a header
function HeaderSelector({ columnHeaders, onSelect, mapColumn }) {
    return (
        <select onChange={(e) => onSelect(e.target.value, mapColumn)}>
            <option>Map column</option>
            {columnHeaders.map((header, index) => (
                <option key={index} value={header}>{header}</option>
            ))}
        </select>
    );
}

function EventRow({ id, rowData, handleInputChange, removeRow, columnValues }) {
    return (
        <div className='grid grid-cols-4 gap-2 items-center justify-around my-2'>
            <select
                name='event'
                onChange={(e) => handleInputChange(id, 'event', e.target.value)}
                value={rowData.event || ''}
            >
                <option>Select a Value</option>
                {columnValues.map((value, index) => (
                    <option key={index} value={value}>{value}</option>
                ))}
            </select>
            <input
                type="text"
                name='message'
                value={rowData.message || ''} // Ensure value isn't undefined
                onChange={(e) => handleInputChange(id, 'message', e.target.value)}
            />
            <input
                type="text"
                name='phone'
                value={rowData.phone || ''} // Ensure value isn't undefined
                onChange={(e) => handleInputChange(id, 'phone', e.target.value)}
            />
            <button className='justify-self-center' onClick={() => removeRow(id)}>
                <Remove fontSize="large" className='bg-red-500 text-white rounded-full px-2 py-2' />
            </button>
        </div>
    );
}

// Main Component
function GoogleSheetEventHandler({
    setExcelColumnValues,
    setColumnHeader,
    userInfo,
    setSelectedData,
    setMapColumnValues,
    setSelectedSheetUrl
}) {
    const {
        sheetEventUrl,
        setSheetEventUrl,
        sheetEventNames,
        selectedEventSheet,
        columnEventHeaders,
        selectedEventHeader,
        columnEventValues,
        fetchSheetEventNames,
        fetchColumnEventHeaders,
        fetchColumnEventValues,
        processingEvent
    } = useGoogleEventSheet();

    const {
        sheetId,
        sheetUrl,
        setSheetUrl,
        sheetNames,
        columnHeaders,
        columnValues,
        fetchSheetNames,
        fetchColumnHeaders,
        fetchColumnValues,
        mapHeaderColumnValues,
        mapColumnValues,
        selectedHeader,
        processing
    } = useGoogleSheet();

    // useEffect(() => {
    //     if (Object.keys(columnValues).length > 0) {

    //         setExcelColumnValues(prev => {
    //             return {
    //                 ...prev,
    //                 ...columnValues
    //             }
    //         });
    //     }

    //     if (Object.keys(columnEventValues).length > 0) {

    //         setExcelColumnValues(prev => {
    //             return {
    //                 ...prev,
    //                 ...columnEventValues
    //             }
    //         });
    //     }
    // }, [columnValues, columnEventValues])

    useEffect(() => {
        if (columnHeaders) {
            setColumnHeader(columnHeaders)
        }
    }, [columnHeaders])

    const [rows, setRows] = useState([]); // State to track rows and their data

    // Add a new row to the state
    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { id: prevRows.length + 1, uid: userInfo.uid, sheetId, message: '', phone: '', event: '' } // Add new row with empty inputs
        ]);
    };

    const handleInputChange = (id, field, value) => {

        setRows(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, [field]: value } : row // Only update the specific field of the affected row
            )
        );
    };

    // Remove row by id
    const removeRow = useCallback((id) => {
        setRows((prevRows) => prevRows.filter(row => row.id !== id));
    }, []);

    useEffect(() => {
        if (rows.length > 0) setSelectedData(rows)
    }, [rows])

    useEffect(() => {
        if (mapColumnValues) setMapColumnValues(mapColumnValues)
    }, [mapColumnValues])

    return (
        <div className="grid grid-cols-1 my-4 ">
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-6'>
                    <div className='flex justify-start gap-2'>
                        <input
                            type="text"
                            value={sheetUrl || sheetEventUrl || ''} // Ensure value isn't undefined
                            onChange={(e) => {
                                setSheetUrl(e.target.value)
                                setSelectedSheetUrl(e.target.value)
                                setSheetEventUrl(e.target.value)
                            }}
                            placeholder="paste google sheet url here"
                        />

                        <div >
                            {!processingEvent && (<button className='w-[160px] rounded-full bg-blue-500 text-white px-4 py-1 text-xs' onClick={() => {
                                fetchSheetNames()
                                fetchSheetEventNames()
                            }}>Sync</button>)}
                            {processingEvent && (<button className='w-[160px] rounded-full bg-blue-500 text-white px-4 py-1 text-xs' disabled>Wait...</button>)}
                        </div>
                    </div>
                </div>
            </div>

            {
                sheetNames &&
                sheetNames.length > 0 &&
                (
                    <>
                        <div className="grid grid-cols-2 my-4 gap-2">
                            <div>
                                <div className="grid grid-cols-2 my-4 items-center">
                                    <div>
                                        <p className="text-xs font-sans text-gray-800 mb-2 font-bold">
                                            Select data page:
                                        </p>
                                    </div>
                                    <div>
                                        <SheetSelector sheetNames={sheetNames} onSelect={fetchColumnHeaders} />
                                    </div>
                                </div>

                                {
                                    columnHeaders.length > 0 && (
                                        <>
                                            <div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Name:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="name" />
                                                </div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Phone:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="phone" />
                                                </div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Message:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="message" />
                                                </div>

                                                <div className="grid grid-cols-2 my-2 items-center">
                                                    <label className=" font-semibold text-gray-800">Trigger column:</label>
                                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={mapHeaderColumnValues} mapColumn="status" />
                                                </div>

                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div>
                                <div className="grid grid-cols-2 my-4 items-center">
                                    <div>
                                        <p className="text-xs font-sans text-gray-800 mb-2 font-bold">
                                            Select trigger page:
                                        </p>
                                    </div>
                                    <div>
                                        <SheetSelector sheetNames={sheetEventNames} onSelect={fetchColumnEventHeaders} />
                                    </div>
                                </div>

                                {
                                    columnEventHeaders.length > 0 && (
                                        <>
                                            <div>
                                                <div>
                                                    <div className="grid grid-cols-2 my-2 items-center">
                                                        <label className=" font-semibold text-gray-800">Trigger values:</label>
                                                        <HeaderSelector columnHeaders={columnEventHeaders} onSelect={fetchColumnEventValues} mapColumn="actions" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>

                        <div className="grid grid-cols-1 my-4">
                            <p className="flex justify-center text-xs font-bold">-----------------------------------------OR-----------------------------------------</p>
                        </div>

                        <div className='grid grid-cols-1 justify-center items-center'>
                            <div>
                                <div>
                                    <div className="grid grid-cols-2 my-2">
                                        <label className="text-sm font-bold text-gray-800 my-2">Set Action : </label>
                                        <button className='justify-self-end'>
                                            <Add fontSize="large" className=' bg-blue-500 text-white rounded-full px-2 py-2' onClick={addRow} />
                                        </button>
                                    </div>
                                </div>
                                <div className='grid grid-cols-4 gap-2 items-center justify-around'>
                                    <div className='bg-gray-800 text-white p-2 text-center'>Trigger</div>
                                    <div className='bg-gray-800 text-white p-2 text-center'>Message</div>
                                    <div className='bg-gray-800 text-white p-2 text-center'>Phone</div>
                                    <div className='bg-gray-800 text-white p-2 text-center'>Delete</div>
                                </div>
                                <div id="eventRow">
                                    {rows.map((row) => (
                                        <EventRow
                                            key={row.id}
                                            id={row.id}
                                            rowData={row}
                                            handleInputChange={handleInputChange}
                                            removeRow={removeRow}
                                            columnValues={columnEventValues.actions}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default GoogleSheetEventHandler;
