import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable';
import moment from "moment";

const GET_SHEET_TRIGGER = gql`
    query SheetsByUserId($uid: String!) {
        sheetsByUserId(uid: $uid) {
            id
            uid
            deviceId
            deviceName
            message
            phone
        }
    }
`;

const columns = [
    { field: "createdAt", headerName: "Date Time", minWidth: 250, align: "left" },
    { field: "deviceId", headerName: "Device ID", minWidth: 250, align: "left" },
    { field: "deviceName", headerName: "Device Name", minWidth: 250, align: "center" },
    { field: "message", headerName: "Message", minWidth: 250, align: "center" },
    { field: "phone", headerName: "Phone", minWidth: 250, align: "center" }
];

function SheetRecords() {
    const [records, setRecords] = useState(null);

    const [sheetError, setSheetError] = useState("");

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const uid = userInfo.uid;

    const {
        data,
        loading,
        error,
    } = useQuery(GET_SHEET_TRIGGER, {
        variables: { uid: uid },
    });

    useEffect(() => {

        if (
            data &&
            data.sheetsByUserId &&
            data.sheetsByUserId.length > 0
        ) {

            let sheetRecords = [...data.sheetsByUserId];

            sheetRecords = sheetRecords.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }).map(user => {
                return {
                    id: moment(new Date(Number(user.createdAt)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                    ...user, createdAt: moment(new Date(Number(user.createdAt)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                }
            });

            setRecords(sheetRecords);
        } else {
            setRecords([]);

        }
    }, [data, loading]);

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setSheetError("");
            }, 5000);
        }
        setSheetError(error)
    }, [error]);

    return (
        <>
            {
                records && records.length >= 0 ? (
                    <DataTable columns={columns} data={records} />
                ) : (
                    <p className="text-xl font-semibold text-red-500 ">
                        no records found
                    </p>
                )
            }
        </>
    );
}

export default SheetRecords
