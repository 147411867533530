import { useEffect, useState } from 'react';
import moment from 'moment';
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";

const DELETE_TEMPLATE = gql`
    mutation($templateId: ID!) {
        templateDelete(templateId: $templateId) {
            templateErrors {
                message
            }
        }
    }
`

const CREATE_TEMPLATE = gql`
    mutation($template:TemplateInput!) {
        templateCreate(template: $template) {
            templateErrors {
                message
            }
            template {
                id
            }
        }
    }
`

const LIST_TEMPLATE = gql`
    query TemplateByUID($uid: String!){
        templateByUID(uid: $uid){
            id
            uid
            templateName
            category
            language
            body
            userId
            createdAt
        }
    }
`

const useTemplate = () => {
    const [records, setRecords] = useState(null);
    const [isPending, setPending] = useState(false);
    const [error, setError] = useState("")

    const userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const [templateCreate] = useMutation(CREATE_TEMPLATE);
    const [deleteTemplate] = useMutation(DELETE_TEMPLATE);


    const {
        refetch,
        data,
        loading
    } = useQuery(LIST_TEMPLATE, {
        variables: { uid: userInfo.uid }
    });

    const [formData, setFormData] = useState({
        uid: "uid",
        userId: 0,
        templateName: 'welcome',
        category: 'Marketing',
        language: 'English (US)',
        body: 'Hi {{name}} 👋,\n\nThank you for your message.\nHow can I help you today?',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = async () => {
        // Logic to save form data
        await templateCreate({
            variables: {
                template: {
                    ...formData,
                    userId: Number(userInfo.id),
                    uid: userInfo.uid
                }
            },
            onCompleted: (resp) => {

                if (
                    resp.templateCreate.templateErrors &&
                    resp.templateCreate.templateErrors.length > 0
                ) {
                    let errorMessage = resp.templateCreate.templateErrors[0].message
                    setError(errorMessage)
                }
                setFormData({
                    uid: "uid",
                    userId: 0,
                    templateName: 'welcome',
                    category: 'Marketing',
                    language: 'English (US)',
                    body: '',
                })

                refetch({ uid: userInfo.uid });
            }
        })

    };

    const handleCancel = () => {
        // Logic to cancel and reset form data
        setFormData({
            templateName: 'welcome_wati_v2',
            category: 'Marketing',
            language: 'English (US)',
            body: 'Hi {{name}} 👋,\n\nThank you for your message.\nHow can I help you today?',
        });
    };

    const handleEdit = (e) => {
        setFormData(e)
    };

    const handleDelete = async (e) => {
        setPending(true);

        let resp = window.confirm("Do you really want to delete template");

        if (resp) {

            //DELETE USER
            deleteTemplate({
                variables: {
                    templateId: Number(e.id),
                },
                refetchQueries: [LIST_TEMPLATE],
                onCompleted: () => setPending(false),
                onError: (error) => {
                    setPending(false)
                    console.log(error)
                }
            });
        }

    };

    useEffect(() => {

        if (
            data &&
            data.templateByUID &&
            data.templateByUID.length > 0
        ) {

            let templateRecords = [...data.templateByUID];

            templateRecords = templateRecords.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }).map(user => {
                return {
                    id: moment(new Date(Number(user.createdAt)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                    ...user, createdAt: moment(new Date(Number(user.createdAt)))
                        .utc()
                        .format("DD-MM-YYYY h:mm:ss.SSS A"),
                }
            });

            setRecords(templateRecords);
        } else {
            setRecords([]);

        }
    }, [data, loading]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("")
            }, 5000)
        }
    }, [error])

    return {
        formData,
        handleChange,
        handleSave,
        handleDelete,
        handleEdit,
        isPending,
        handleCancel,
        records,
        error
    };
};

export default useTemplate;
