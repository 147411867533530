import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import DataTable from "../../components/DataTable";
import Container from "../../components/Container";

const GET_DELIVERY_REPORT = gql`
  query getDeliveryReports($uid: String) {
    deliveryReports(uid: $uid) {
      userId
      from
      to
      message
      attachment
      status
      createdAt
    }
  }
`;

const columns = [
  { field: "createdAt", headerName: "Date Time", minWidth: 250, align: "left" },
  { field: "message", headerName: "Message", minWidth: 130, align: "left" },
  { field: "from", headerName: "From", minWidth: 100, align: "center" },
  { field: "to", headerName: "To", minWidth: 100, align: "center" },
  {
    field: "attachment",
    headerName: "Attachment",
    minWidth: 130,
    align: "left",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 130,
    align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
];

export default function Delivery() {
  const [data, setData] = useState(null);

  const [error, setError] = useState("");

  const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  const uid = userInfo.uid;

  const {
    data: deliveryReportsData,
    loading: deliveryReportsLoading,
    error: deliveryReportsError,
  } = useQuery(GET_DELIVERY_REPORT, {
    variables: { uid: uid },
  });

  useEffect(() => {

    if (
      deliveryReportsData &&
      deliveryReportsData.deliveryReports &&
      deliveryReportsData.deliveryReports.length > 0
    ) {

      let data = [...deliveryReportsData.deliveryReports];

      data = data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }).map(user => {
        return {
          id: moment(new Date(Number(user.createdAt)))
            .utc()
            .format("DD-MM-YYYY h:mm:ss.SSS A"),
          ...user, createdAt: moment(new Date(Number(user.createdAt)))
            .utc()
            .format("DD-MM-YYYY h:mm:ss.SSS A"),
        }
      });

      setData(data);
    } else {
      setData([]);

    }
  }, [deliveryReportsData, deliveryReportsLoading]);

  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  return (
    <div className="flex w-screen h-screen bg-white">
      <Sidebar />
      <Container>
        <div>
          <p className="text-green-600  flex justify-center items-center py-4 text-xl font-semibold">DELIVERY REPORTS</p>
        </div>

        {!deliveryReportsLoading && data && (
          <div className="grid grid-cols-12 gap-2  p-4 pt-0 h-screen overflow-y-scroll no-scrollbar">
            <div className="col-span-12 self-center ">
              <div className="my-2 text-sm text-red-600 text-center">Every saturday we remove all delivery reports, Please download</div>
              {data.length >= 0 ? (
                <DataTable columns={columns} data={data} />
              ) : (
                <p className="text-xl font-semibold text-red-500 ">
                  no records found
                </p>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
