import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ContactPhone, Delete, Download } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Alerts from "../../components/Alerts";
import axios from "axios";
import { Box, CircularProgress, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { CopyPlus, HardDriveDownload, Recycle, Trash, View } from 'lucide-react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import useGroup from '../../hook/useGroup';
import GroupSelector from './GroupSelector';

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      name
      email
      mobile
      password
      address
      country
      doj
      planType
      userType
      expireInDays
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

// const GROUP_GET = gql`
//     query($userId: Int!){
//         groupsByID(userId: $userId) {
//             id
//             groupName
//             waGroupId
//         }
//     }
// `;

const GROUP_GET = gql`
    query ($userId: Int!) {  
        groupsByID(userId: $userId) {    
            id    
            groupName    
            contacts {waGroupId}  
        }
    }
`;

const GROUP_GET_BY_GROUPID = gql`
    query($waGroupId: String!){
        contactsByWaGroupID(waGroupId: $waGroupId) {
            id
            groupId
            formattedName
            phone
            waGroupId
            waContact
            isWAContact
        }
    }
`;

const ADD_GROUP = gql`
    mutation($group: GroupInput!){
        groupCreate(group: $group) {
            group {
                id
            }
        }
    }
`;

const DELETE_GROUP = gql`
    mutation($groupId: ID!){
        groupDelete(groupId: $groupId) {
            group {
                id
            }
        }
    }
`;

const GET_CONTACTS = gql`
    query($groupId: Int!){
        contactsByGroupID(groupId: $groupId) {
            id
            groupId
            waGroupId
            formattedName
            waContact
            phone
        }
    }
`;

const UPDATE_CONTACTS = gql`
    mutation($contactId: ID!, $contact: ContactInput!){
        contactUpdate(contactId: $contactId, contact: $contact) {
            contact {
                id
            }
        }
    }
`;

const ADD_CONTACTS = gql`
    mutation($contact: ContactInput!){
        contactCreate(contact: $contact) {
            contact {
                id
            }
        }
    }
`;

const DELETE_CONTACTS = gql`
    mutation($contactId: ID!){
        contactDelete(contactId: $contactId) {
            contact {
            id
            }
        }
    }
`;

const useFakeMutation = () => {
    return React.useCallback(
        (user) =>
            new Promise((resolve, reject) =>
                setTimeout(() => {
                    if (user.name?.trim() === '') {
                        reject(new Error("Error while saving user: name can't be empty."));
                    } else {
                        resolve({ ...user, name: user.name?.toUpperCase() });
                    }
                }, 200),
            ),
        [],
    );
};

function Group({ handleGroupContact }) {

    const [selectedGroupContacts, setSelectedGroupContacts] = useState([])
    const [rowModesModel, setRowModesModel] = React.useState({});

    const {
        createGroup,
        updateGroup,
        deletePhoneGroup,
        addGroupParticipant,
        updateGroupParticipant,
        deleteGroupParticipant,
        migrateGroupParticipant
    } = useGroup()

    const columns = [
        { field: 'groupId', headerName: 'GID', width: 70, editable: false },
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'formattedName',
            headerName: 'Full name',
            width: 300,
            editable: true,
            renderEditCell: (params) => {
                const handleChange = (event) => {
                    // Update the cell's value using the API provided by DataGrid
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: event.target.value,
                    });
                };

                return (
                    <TextField
                        value={params.value || ''} // Ensure the value is controlled
                        onChange={handleChange} // Update the value on change
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                );
            },
        },
        {
            field: 'phone',
            headerName: 'Phone',
            type: 'string',
            width: 200,
            editable: true,
            renderEditCell: (params) => {
                const handleChange = (event) => {
                    // Update the cell's value using the API provided by DataGrid
                    params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: event.target.value,
                    });
                };

                return (
                    <TextField
                        value={params.value || ''} // Ensure the value is controlled
                        onChange={handleChange} // Update the value on change
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                );
            },
        },
        { field: 'waGroupId', headerName: 'Group ID', width: 300, editable: false },
        { field: 'waContact', headerName: 'WA Contact', width: 200, editable: false },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: (props) => {
                let { id } = props

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(props)}
                        color="inherit"
                    />,
                ];
            },
        },

    ];

    const theme = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#fafbfc',
                darker: '#053e85',
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
        },
    });

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const groupNameRef = useRef("");
    const phonesFile = useRef("");
    const [contacts, setContacts] = useState([]);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [migration, setMigration] = useState(false);
    const [migrationType, setMigrationType] = useState("")
    const [userProfile, setUserProfile] = useState({});
    const [allGroups, setAllGroups] = useState([]);
    const [uploadFileVisible, setUploadFileVisible] = useState(false);
    const [devices, setDevices] = useState([]);
    const [waGroups, setWaGroups] = useState([]);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const [showGroupContacts, setShowGroupContacts] = useState(false);
    const connectedDeviceRef = useRef("");
    const selectedGroupRef = useRef("");
    const selectedGroupContactRef = useRef("");

    const [addGroup] = useMutation(ADD_GROUP, {
        refetchQueries: [
            GROUP_GET, {
                variables: {
                    "userId": Number(userProfile.id)
                }
            }
        ]
    })

    const [deleteGroup] = useMutation(DELETE_GROUP, {
        refetchQueries: [
            GROUP_GET,
            {
                variables: {
                    "userId": Number(userProfile.id)
                }
            }
        ]
    })

    const { data } = useQuery(USER_GET, {
        variables: { uid: userInfo.uid },
    });

    const { data: deviceData, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const [groups, { data: groupsData }] = useLazyQuery(GROUP_GET);
    const [getContactByWaGroupID, { data: contactsByWaGroupID }] = useLazyQuery(GROUP_GET_BY_GROUPID);
    const [getContacts, { data: contactsData }] = useLazyQuery(GET_CONTACTS);
    const [updateContact] = useMutation(UPDATE_CONTACTS, {
        refetchQueries: [
            GET_CONTACTS,
            {
                variables: {
                    "groupId": Number(selectedGroupContactRef.current.id)
                }
            }
        ]
    });

    const [createContact] = useMutation(ADD_CONTACTS, {
        refetchQueries: [
            GET_CONTACTS,
            {
                variables: {
                    "groupId": Number(selectedGroupContactRef.current.id)
                }
            }
        ]
    });

    const [deleteContact] = useMutation(DELETE_CONTACTS, {
        refetchQueries: [
            GET_CONTACTS,
            {
                variables: {
                    "groupId": Number(selectedGroupContactRef.current.id)
                }
            }
        ]
    });

    const [processing, setProcessing] = useState(false);
    const [exportProcessing, setExportProcessing] = useState(false);
    const [phoneContactsInProgress, setPhoneContactsInProgress] = useState(false)
    const [phoneGroupsInProgress, setPhoneGroupsInProgress] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState([])
    const [nameNotFound, setNameNotFound] = useState("")
    const [transferProcessing, setTransferProcessing] = useState(false);

    const resetAll = () => {
        setProcessing(false);
        setExportProcessing(false);
        setPhoneContactsInProgress(false);
        setPhoneGroupsInProgress(false);
        setDeleteProcessing(false);
        setContacts([]);
        setWaGroups([]);
        setSelectedContacts([])
        setMigration(false);
        setUploadFileVisible(false);

        if (groupNameRef.current) groupNameRef.current.value = "";
        if (connectedDeviceRef.current) connectedDeviceRef.current.value = "";
        if (selectedGroupRef.current) selectedGroupRef.current.value = "";
    }

    const containsNumbers = (str) => {
        return /[0-9]/.test(str);
    }

    let allContacts = [];
    let formattedContacts = [];
    let indexRef = 0;

    const findName = async (contact) => {

        if (
            !containsNumbers(contact.formattedName)
        ) {
            formattedContacts.push({ ...contact })
        } else {
            setNameNotFound(`${contact.phone} has no contact name.Please wait... Getting contact name from WhatsApp.`)
            try {
                let config = {
                    method: 'get',
                    url: `https://mrkgroups.com/truecaller/${contact.phone}`,
                    headers: {}
                };

                let response = await axios(config)

                let result = response.data;

                if (result.status === "success") {
                    formattedContacts.push({ ...contact, formattedName: result.message });
                } else {
                    formattedContacts.push({ ...contact });
                }
            } catch (error) {
                formattedContacts.push({ ...contact });
            }
        }

        ++indexRef;

        if (indexRef < allContacts.length) {
            await findName(allContacts[indexRef])
        }

        return
    }

    const handleAllGroupsMember = async (e) => {
        e.preventDefault();

        let groupName = groupNameRef.current?.value.trim()

        if (groupName === "") {
            setError("Group name is required");
            setProcessing(false);
        } else if (selectedGroupRef.current.value !== "") {
            setProcessing(true);

            try {

                let config = {
                    method: 'get',
                    url: `https://mrkgroups.com/contact/getGroupMembers/${userInfo.uid}/${connectedDeviceRef.current.value}/${selectedGroupRef.current.value}`,
                    headers: {}
                };

                let response = await axios(config);

                if (response.data.length > 0) {

                    formattedContacts = response.data.map(c => {
                        return {
                            formattedName: c.name,
                            isWAContact: c.isWAContact,
                            waGroupId: selectedGroupRef.current.value,
                            waContact: c.id,
                            phone: c.number
                        }
                    })

                }

                setNameNotFound("")
                setContacts(formattedContacts);
                setProcessing(false);
            } catch (error) {
                setProcessing(false);
                setError(error.response?.data?.message);
            }

        } else {
            setProcessing(false);
            setError("Please select connected phone group");

        }
    }

    const handleWAAllGroups = () => {
        // e.preventDefault();

        if (connectedDeviceRef.current.value !== "") {
            setPhoneGroupsInProgress(true);
            setProcessing(true);

            let config = {
                method: 'get',
                url: `https://mrkgroups.com/contact/getAllGroups/${userInfo.uid}/${connectedDeviceRef.current.value}`,
                headers: {}
            };

            axios(config)
                .then((response) => {
                    let data = response.data;
                    if (data.length > 0) {
                        setWaGroups(data);
                    }

                    setPhoneGroupsInProgress(false);
                    setProcessing(false);
                })
                .catch((error) => {
                    setPhoneGroupsInProgress(false);
                    setProcessing(false);
                    setError(error.response.data.message)

                });
        } else {
            setPhoneGroupsInProgress(false);
            setProcessing(false);
            setError("Select any connected device");

        }

    }

    const handlePhoneContacts = async (e) => {
        e.preventDefault();
        setPhoneContactsInProgress(true);

        if (connectedDeviceRef.current.value !== "") {
            setProcessing(true);

            try {

                let config = {
                    method: 'get',
                    url: `https://mrkgroups.com/contact/getAllContacts/${userInfo.uid}/${connectedDeviceRef.current.value}`,
                    headers: {}
                };

                let response = await axios(config);

                if (response.data.length > 0) {
                    response.data.forEach((row) => {
                        allContacts.push(
                            {
                                formattedName: row?.verifiedName ? row?.verifiedName?.toString().trim() : row?.pushname ? row?.pushname?.toString().trim() : row?.formattedName?.toString().trim(),
                                isWAContact: row?.isWAContact,
                                phone: row.id.user.toString().trim()
                            }
                        );

                    });
                }

                allContacts = allContacts.filter(
                    d =>
                        d.isWAContact &&
                        !d.formattedName.includes("+")
                );

                setContacts(allContacts);
                setProcessing(false);
                setPhoneContactsInProgress(false);
            } catch (error) {
                setProcessing(false);
                setPhoneContactsInProgress(false);
                setError(error.response.data.message);

            }
        } else {
            setProcessing(false);
            setPhoneContactsInProgress(false);
            setError("Select any connected device");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);

        let groupName = groupNameRef.current && groupNameRef.current.value ? groupNameRef.current.value.trim() : ""

        if (groupName === "") {
            setError("Group name is required");
            setProcessing(false);
        } else if (contacts.length === 0) {
            setError("Import contatct from excel sheet");
            setProcessing(false);
        } else {

            // let waGroupId = ""
            // let participants = contacts.map(d => d.waContact)

            //ADD TO PHONE
            // const response = await createGroup(
            //     {
            //         "id": userInfo.uid,
            //         "deviceName": connectedDeviceRef.current.value,
            //         "groupName": groupName,
            //         "participants": participants
            //     }
            // )

            // if (response.status === "success") {
            //     waGroupId = response.message.gid._serialized
            // }

            if (connectedDeviceRef.current.value !== "") {
                try {

                    let formatedContacts = contacts.map(sc => {
                        return {
                            "formattedName": sc.formattedName,
                            "isWAContact": sc.isWAContact,
                            "phone": sc.phone,
                            // "waGroupId": waGroupId,
                            "waGroupId": selectedGroupRef.current.value,
                            "waContact": sc.waContact
                        }
                    })

                    //ADD TO DATABASE
                    await addGroup({
                        variables: {
                            "group": {
                                "groupName": groupName,
                                "userId": Number(userProfile.id),
                                "contacts": formatedContacts
                            }
                        }
                    });

                    setTransferProcessing(false); // Stop the loading state
                    setMessage("Successfully created!"); // Set success message
                    resetAll();
                } catch (error) {
                    setTransferProcessing(false); // Stop the loading state even in case of an error
                    setError("Error occurred during transfer. Please try again."); // Set error message
                }
            } else {
                setError("Select a connected device");
                setTransferProcessing(false);
                resetAll();
            }
        }
    }

    const handleExcelFile = async (e) => {
        const map = {
            Name: "Name",
            Phone: "Phone",
            Message: "Message",
        };

        let file = phonesFile.current.files[0];
        let contacts = [];

        await readXlsxFile(file, { map }).then(({ rows }) => {

            rows.forEach((row) => {
                contacts.push({
                    formattedName: row.Name.toString().trim(),
                    isWAContact: true,
                    phone: row.Phone.toString().trim()
                }
                );

            });
        });

        setContacts(contacts);

    };

    const handleContacts = async (group) => {
        setExportProcessing(true);

        if (group && group.id) {
            await getContacts({
                variables: {
                    "groupId": Number(group.id)
                }
            });
        }

        setExportProcessing(false);
    }

    const handleDelete = async (id, groupId) => {
        setProcessing(true)

        let deviceName = connectedDeviceRef.current.value;
        let userId = userInfo.uid;

        console.log("deviceName", deviceName)
        console.log("userId", userId)
        console.log("groupId", groupId)

        if (connectedDeviceRef.current.value !== "") {
            if (
                groupId
            ) {
                //Delete from Database
                await deleteGroup({ variables: { groupId: id } })

                if (userInfo.uid) {
                    //Delete from Phone
                    await deletePhoneGroup({ userId, deviceName, groupId })
                } else {
                    setError("User Info is missing")
                    setProcessing(false)
                }
            } else {
                setError("Group ID is missing")
                setProcessing(false)
            }
        } else {
            setError("Select any connected device");
            setProcessing(false);
        }
    }

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => selectedGroupContacts.find((row) => row.id === id));
        setSelectedContacts(selectedRowsData);
    };

    const handleDeleteSelectedContacts = async (e) => {
        e.preventDefault();

        setDeleteProcessing(true);
        let allDeletePromises = [];

        if (connectedDeviceRef.current.value !== "") {

            selectedContacts.forEach((sc) => {

                //DELETE FROM DATABASE
                allDeletePromises.push(new Promise(async () => {
                    await deleteContact(
                        {
                            variables: { "contactId": sc.id }
                        }
                    )
                }))

                //DELETE FROM PHONE
                allDeletePromises.push(new Promise(async () => {
                    await deleteGroupParticipant(
                        {
                            "id": userInfo.uid,
                            "deviceName": connectedDeviceRef.current.value,
                            "groupId": sc.waGroupId,
                            "participantNumber": sc.phone,
                        }
                    )
                }))
            });

            await Promise.all(allDeletePromises.map(f => f));
        } else {
            setError("Select any connected device");
            setDeleteProcessing(false);
        }

    }

    const handleSelectedContacts = () => {
        handleGroupContact(selectedContacts);
    }

    const handleTransferContacts = async () => {

        let dbRecord = contactsByWaGroupID?.contactsByWaGroupID

        setTransferProcessing(true); // Set loading state to true
        setMessage(""); // Clear any previous success message
        setError(""); // Clear any previous error message

        let allTransferPromises = [];

        if (connectedDeviceRef.current.value !== "") {

            selectedContacts.forEach((sc) => {
                // Push a proper Promise that resolves after the async operation
                allTransferPromises.push(new Promise(async (resolve, reject) => {
                    try {
                        //ADD TO DATABASE
                        await createContact({
                            variables: {
                                "contact": {
                                    groupId: parseInt(dbRecord.groupId),
                                    formattedName: sc.formattedName,
                                    phone: sc.phone.toString(),
                                    isWAContact: true,
                                    waGroupId: dbRecord.waGroupId,
                                    waContact: `${sc.phone}@c.us`,
                                }
                            }
                        })

                        //ADD TO PHONE CONTACT
                        await addGroupParticipant({
                            "id": userInfo.uid,
                            "deviceName": connectedDeviceRef.current.value,
                            "groupId": selectedGroupRef.current.value,
                            "participantNumber": sc.phone,
                        });

                        resolve(); // Resolve the promise after successful transfer
                    } catch (error) {
                        reject(error); // Reject the promise in case of failure
                    }
                }));
            });

            try {
                // Wait for all promises to resolve
                await Promise.all(allTransferPromises);

                setTransferProcessing(false); // Stop the loading state
                setMessage("Successfully transferred!"); // Set success message
                resetAll();
            } catch (error) {
                setTransferProcessing(false); // Stop the loading state even in case of an error
                setError("Error occurred during transfer. Please try again."); // Set error message
            }
        } else {
            setError("Select a connected device"); // Set error if no device is selected
            setTransferProcessing(false); // Stop the loading state
        }
    };

    const handleCancel = () => {
        setShowGroupContacts(false);
        setSelectedContacts([])
        setWaGroups([])
        setMigration(false);
    }

    const handleSelectedGroupContact = (group) => {
        selectedGroupContactRef.current = group
        handleContacts(group);
        setShowGroupContacts(!showGroupContacts)
    }

    const createNewGroup = async (groupName) => {
        setTransferProcessing(true);
        setMessage("");
        setError("");


        let waGroupId = ""
        let participants = selectedContacts.map(d => d.waContact)

        //ADD TO PHONE
        const response = await createGroup(
            {
                "id": userInfo.uid,
                "deviceName": connectedDeviceRef.current.value,
                "groupName": groupName,
                "participants": participants

            }
        )

        if (response.status === "success") {
            waGroupId = response.message.gid._serialized
        }

        if (connectedDeviceRef.current.value !== "") {

            try {

                let contacts = selectedContacts.map(sc => {
                    return {
                        "formattedName": sc.formattedName,
                        "isWAContact": sc.isWAContact,
                        "phone": sc.phone,
                        "waGroupId": waGroupId,
                        "waContact": sc.waContact
                    }
                })

                //ADD TO DATABASE
                await addGroup({
                    variables: {
                        "group": {
                            "groupName": groupName,
                            "userId": Number(userProfile.id),
                            "contacts": contacts
                        }
                    }
                });

                setTransferProcessing(false); // Stop the loading state
                setMessage("Successfully created!"); // Set success message
                resetAll();
            } catch (error) {
                setTransferProcessing(false); // Stop the loading state even in case of an error
                setError("Error occurred during transfer. Please try again."); // Set error message
            }
        } else {
            setError("Select a connected device"); // Set error if no device is selected
            setTransferProcessing(false); // Stop the loading state
            resetAll();
        }
    }

    const mutateRow = useFakeMutation();

    const [snackbar, setSnackbar] = React.useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            setDeleteProcessing(true);
            // Make the HTTP request to save in the backend
            const response = await mutateRow(newRow);

            let {
                id,
                groupId,
                formattedName,
                phone,
                waGroupId,
                isWAContact = true,
                isNew
            } = response;

            if (isNew) {

                if (connectedDeviceRef.current.value !== "") {

                    //CREATE TO DATABASE
                    await createContact({
                        variables: {
                            "contact": {
                                groupId: parseInt(groupId),
                                formattedName,
                                phone: phone.toString(),
                                isWAContact: true,
                                waGroupId,
                                waContact: `${phone}@c.us`,
                            }
                        }
                    })

                    //ADD TO PHONE
                    await addGroupParticipant(
                        {
                            "id": userInfo.uid,
                            "deviceName": connectedDeviceRef.current.value,
                            "groupId": waGroupId,
                            "participantNumber": phone,
                        }
                    )

                } else {
                    setError("Select any connected device");
                    setDeleteProcessing(false);
                }

                setSnackbar({ children: 'Record added successfully', severity: 'success' });
                return response;
            } else {

                updateContact({
                    variables: {
                        "contactId": id,
                        "contact": {
                            groupId: parseInt(groupId),
                            formattedName,
                            phone,
                            isWAContact,
                            waGroupId,
                            waContact: `${phone}@c.us`,
                        }
                    }
                });

                setSnackbar({ children: 'Record successfully saved', severity: 'success' });
                return response;
            }

        },
        [mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    //table
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (rowData) => async () => {

        setDeleteProcessing(true);

        if (connectedDeviceRef.current.value !== "") {
            let {
                id,
                phone,
                waGroupId
            } = rowData.row

            //DELETE FROM LOCAL DATABASE
            await deleteContact(
                {
                    variables: { "contactId": id }
                }
            )

            //DELETE FROM PHONE
            await deleteGroupParticipant(
                {
                    "id": userInfo.uid,
                    "deviceName": connectedDeviceRef.current.value,
                    "groupId": waGroupId,
                    "participantNumber": phone,
                }
            )

            setSelectedGroupContacts(selectedGroupContacts.filter((row) => row.id !== id));
        } else {
            setError("Select any connected device");
            setDeleteProcessing(false);
            setSelectedGroupContacts(selectedGroupContacts);
        }

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = selectedGroupContacts.find((row) => row.id === id);
        if (editedRow.isNew) {
            setSelectedGroupContacts(selectedGroupContacts.filter((row) => row.id !== id));
        }
    };

    const fetchContactByWaGroupID = async (selectedGroup) => {

        await getContactByWaGroupID({
            variables: {
                "waGroupId": selectedGroup
            }
        });
    }

    // State to store the selected device
    const [selectedDevice, setSelectedDevice] = useState("");

    // Function to handle the selection change
    const handleDeviceChange = (event) => {
        setSelectedDevice(event.target.value);

    };

    function EditToolbar(props) {
        const { setSelectedGroupContacts, setRowModesModel } = props;

        const handleClick = () => {
            let id = 0;
            let ids = [];

            // get largest number
            ids = selectedGroupContacts.map(d => Number(d.id))
            ids = ids.sort()
            id = ids[ids.length - 1] + 1

            setSelectedGroupContacts((oldRows) => [
                ...oldRows,
                { id, groupId: selectedGroupContacts[0].groupId, formattedName: '', phone: '', waContact: '', waGroupId: selectedGroupContacts[0].waGroupId, isNew: true },
            ]);

            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'formattedName' },
            }));
        };

        return (
            <GridToolbarContainer>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='grid grid-cols-2 gap-2 justify-center items-center'>
                        <label htmlFor="connectedDevice" className='text-xs text-gray-600 font-semibold'>SELECT DEVICE : </label>
                        <select
                            name="connectedDevice"
                            id="connectedDevice"
                            ref={connectedDeviceRef}
                            value={selectedDevice}
                            onChange={handleDeviceChange}
                        >
                            <option value="">Select Connected Device</option>
                            {
                                devices.map(device => {
                                    return (
                                        <option value={device.deviceName} key={device.deviceName}>{device.deviceName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='grid grid-cols-2 gap-2 justify-center items-center'>
                        <label htmlFor="connectedDevice" className='text-sm text-gray-600 font-semibold'></label>
                        <button className='place-items-end text-xs bg-blue-500 text-white rounded-full px-1 py-2' onClick={handleClick}>
                            Add Manually
                        </button>
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }
    //table

    const [selectedGroup, setSelectedGroup] = useState("Select anyone WA Group")
    const handleSelectGroup = async (event) => {
        setSelectedGroup(event.target.value)
        await fetchContactByWaGroupID(event.target.value)
    }

    useEffect(() => {
        let devicesByUID = null;
        if (deviceData) {
            devicesByUID = deviceData.devicesByUID;
            devicesByUID = devicesByUID.filter(dm => dm.isConnected)
            setDevices(devicesByUID);
        }
    }, [deviceData, loading]);

    useEffect(() => {
        let userProfile = null;
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);

            groups(
                {
                    variables: {
                        "userId": Number(userProfile.id)
                    }
                }
            )
        }
    }, [data]);

    useEffect(() => {
        let groups = [];
        if (groupsData) {
            groups = groupsData.groupsByID
            setAllGroups(groups)
            setShowGroupContacts(false);
        }
    }, [groupsData])

    useEffect(() => {

        let gcontacts = [];

        if (contactsData && contactsData.contactsByGroupID.length > 0) {
            gcontacts = contactsData.contactsByGroupID
            setSelectedGroupContacts(gcontacts);
        }

        setShowGroupContacts(true);
        setDeleteProcessing(false);

    }, [contactsData])

    useEffect(() => {
        setTimeout(() => {
            setError("");
            setMessage("")
        }, 5000)
    }, [error, message])

    return (
        <div className='gris grid-cols-1 gap-2 w-[50%] p-4'>

            <div className='flex justify-center items-center'>
                {error !== "" && <Alerts alertType="Error">{error}</Alerts>}
                {message !== "" && <Alerts alertType="Success">{message}</Alerts>}
                {nameNotFound !== "" && <Alerts alertType="Error">{nameNotFound}</Alerts>}
            </div>

            <div>
                {
                    allGroups &&
                    allGroups.length > 0 &&
                    !showGroupContacts &&
                    (
                        <div>
                            <p className='text-center text-green-600 mt-4  border-b-blue-500 text-blackfont-semibold font-serif text-md tracking-widest px-4 py-2 space-y-2 '>SAVED GROUPS</p>
                            <ul className='h-[300px] overflow-y-scroll  m-auto'>
                                {
                                    allGroups.map((group, index) => {
                                        return (
                                            <li className={`flex justify-between items-center bg-lime-500 px-4 py-1 rounded-xl mt-2 text-white`} key={group.id}>
                                                {
                                                    exportProcessing ?
                                                        (
                                                            <ThemeProvider theme={theme}>
                                                                <CircularProgress size={14} color="primary" />
                                                            </ThemeProvider>
                                                        ) : (
                                                            <ContactPhone />
                                                        )
                                                }

                                                <p>{group.groupName}</p>
                                                <div className='flex justify-center items-center gap-2 cursor-pointer'>
                                                    <button onClick={() => handleSelectedGroupContact(group)}>
                                                        <View />
                                                    </button>
                                                    <button onClick={async () => await handleDelete(group.id, group.contacts[index].waGroupId)}>
                                                        <Trash />
                                                    </button>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                }
                {/* {
                        selectedGroupContacts.length === 0 &&
                        (
                            <div>
                                <p className='text-green-600 font-serif text-sm mt-6'>
                                    Note:- Click above to select anyone group.
                                </p>
                            </div>
                        )
                    } */}
            </div>

            <div className=''>
                {
                    !showGroupContacts && (
                        <div>
                            <div>
                                <p className="text-green-600  flex justify-center items-center py-4 text-SM font-semibold">SAVE NEW GROUPS FROM WA</p>
                            </div>

                            <form onSubmit={handleSubmit}>

                                {
                                    uploadFileVisible &&
                                    (
                                        <div className=''>
                                            <input
                                                type="file"
                                                id="input"
                                                ref={phonesFile}
                                                onChange={handleExcelFile}
                                                accept=".xlsx"
                                                className="my-2 text-right"
                                            />
                                        </div>
                                    )
                                }

                                {
                                    uploadFileVisible && (
                                        <div className='flex justify-start items-center'>
                                            <p className="inline-block text-xs font-bold uppercase leading-8 tracking-wider text-black">
                                                Download Sample Excel File
                                            </p>
                                            <a
                                                href="./assets/samples.xlsx"
                                                className="text-md ml-4 h-8 w-8 rounded-full bg-white font-semibold leading-8 text-blue-400"
                                            >
                                                <Download />
                                            </a>
                                        </div>
                                    )
                                }

                                {
                                    !uploadFileVisible &&
                                    (
                                        <div className='my-2'>
                                            <select name="connectedDevice" id="connectedDevice" ref={connectedDeviceRef} value={selectedDevice}
                                                onChange={handleDeviceChange}>
                                                <option value="">Select Connected Device</option>
                                                {
                                                    devices.map(device => {
                                                        return (
                                                            <option value={device.deviceName} key={device.deviceName}>{device.deviceName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    )

                                }

                                {
                                    waGroups && (
                                        waGroups.length > 0 &&
                                        (
                                            <div className='my-2'>
                                                <select
                                                    name="selectedGroup"
                                                    id="selectedGroup"
                                                    ref={selectedGroupRef}
                                                    value={selectedGroup}
                                                    onChange={handleSelectGroup}
                                                >
                                                    <option value="">Select anyone WA Group</option>
                                                    {
                                                        waGroups.map(group => {
                                                            return <option value={`${group.groupId}`} key={`${group.groupId}`}>{group.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        )
                                    )
                                }



                                {
                                    (
                                        waGroups.length > 0 ||
                                        contacts.length > 0 ||
                                        uploadFileVisible

                                    ) &&
                                    (
                                        <div className='flex justify-start items-center' >
                                            <div className='flex-1'>
                                                <input type="text" name="groupName" id="groupName" ref={groupNameRef} required placeholder="Give a name to your import group" />
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    (
                                        !contacts.length > 0 &&
                                        !waGroups.length > 0 &&
                                        !uploadFileVisible
                                    ) && (<div className='text-sm text-black uppercase flex justify-center w-full'></div>)
                                }



                                <div className='flex justify-center items-center space-x-2'>
                                    {/* {
                                        contacts.length === 0 &&
                                        !uploadFileVisible &&
                                        waGroups.length === 0 &&
                                        (
                                            <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-green-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={handlePhoneContacts} >

                                                {
                                                    phoneContactsInProgress ?
                                                        (
                                                            <ThemeProvider theme={theme}>
                                                                <CircularProgress size={14} color="primary" />
                                                            </ThemeProvider>
                                                        ) : (
                                                            <HardDriveDownload />
                                                        )
                                                }

                                                <span>All WA Contacts</span>
                                            </button>
                                        )
                                    } */}
                                    {

                                        waGroups.length === 0 &&
                                            !uploadFileVisible &&
                                            contacts.length === 0
                                            ? (
                                                <button
                                                    className='flex justify-center items-center space-x-2 px-2 py-1 bg-violet-500 disabled:bg-violet-300 text-white mt-2 rounded-full text-xs w-[200px]'
                                                    onClick={handleWAAllGroups}
                                                    disabled={selectedDevice !== "" ? false : true}
                                                >
                                                    {
                                                        phoneGroupsInProgress ?
                                                            (
                                                                <ThemeProvider theme={theme}>
                                                                    <CircularProgress size={24} color="primary" />
                                                                </ThemeProvider>
                                                            ) : (
                                                                <HardDriveDownload />
                                                            )
                                                    }

                                                    {
                                                        selectedDevice !== "" ? <span>View all WA groups</span> : <span>Select device</span>
                                                    }
                                                </button>
                                            ) : (
                                                waGroups.length > 0 &&
                                                contacts.length === 0 &&
                                                (
                                                    <>
                                                        <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-lime-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={handleAllGroupsMember}>
                                                            <HardDriveDownload />
                                                            <span>Import</span>
                                                        </button>
                                                        <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-red-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={() => {
                                                            setWaGroups([])
                                                            setSelectedDevice("")
                                                        }}>
                                                            <CancelIcon />
                                                            <span>Cancel</span>
                                                        </button>
                                                    </>
                                                )
                                            )
                                    }
                                    {/* {

                                        contacts.length === 0 &&
                                        waGroups.length === 0 &&
                                        (
                                            <>
                                                <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-blue-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={() => setUploadFileVisible(!uploadFileVisible)}>
                                                    <HardDriveDownload />
                                                    <span>Excel</span>
                                                </button>
                                                {
                                                    uploadFileVisible && (
                                                        <button className='flex justify-center items-center space-x-2 px-2 py-1 bg-red-500 text-white mt-2 rounded-full text-xs w-[150px]' onClick={() => setUploadFileVisible(!uploadFileVisible)}>
                                                            <CancelIcon />
                                                            <span>Cancel</span>
                                                        </button>
                                                    )
                                                }

                                            </>

                                        )
                                    } */}



                                    {
                                        contacts.length > 0 &&
                                        (
                                            <button type='submit' className='flex justify-center items-center space-x-2 px-2 py-1 bg-orange-400 text-white mt-2 rounded-full text-xs w-[150px]' disabled={processing}>

                                                {
                                                    processing ?
                                                        (
                                                            <ThemeProvider theme={theme}>
                                                                <CircularProgress size={14} color="primary" />
                                                            </ThemeProvider>
                                                        ) : (
                                                            <HardDriveDownload />
                                                        )
                                                }

                                                <span className='mx-1 bg-orange-400'>Save</span>

                                            </button>
                                        )
                                    }

                                </div>

                            </form>
                        </div>
                    )
                }
            </div>

            <div>
                {
                    selectedGroupContacts &&
                    selectedGroupContacts.length > 0 &&
                    showGroupContacts &&
                    (
                        <div className='bg-white p-4'>
                            <h1 className='text-center text-sm font-serif font-medium tracking-widest my-2 text-green-600'>GROUP CONTACTS</h1>
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    '& .actions': {
                                        color: 'text.secondary',
                                    },
                                    '& .textPrimary': {
                                        color: 'text.primary',
                                    },
                                }}>
                                <DataGrid
                                    rows={selectedGroupContacts}
                                    columns={columns}
                                    pageSize={100}
                                    editMode="row"
                                    rowModesModel={rowModesModel}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    onRowEditStop={handleRowEditStop}
                                    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                    processRowUpdate={processRowUpdate}
                                    onProcessRowUpdateError={handleProcessRowUpdateError}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    columnVisibilityModel={{
                                        groupId: false,
                                    }}
                                    components={{
                                        Toolbar: EditToolbar, // Use components or slots depending on your MUI version
                                    }}
                                    componentsProps={{
                                        toolbar: { setSelectedGroupContacts, setRowModesModel },
                                    }}
                                />
                                {!!snackbar && (
                                    <Snackbar
                                        open
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        onClose={handleCloseSnackbar}
                                        autoHideDuration={5000}
                                    >
                                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                    </Snackbar>
                                )}
                            </Box>
                            {

                                migration &&
                                waGroups.length > 0 &&
                                (
                                    <div className='grid grid-cols-2 gap-2 my-4 items-center'>
                                        <div className='text-sm text-gray-600 font-semibold'>ALL WA GROUPS : </div>
                                        <div>

                                            <div className='my-2'>
                                                <select name="selectedGroup" id="selectedGroup" ref={selectedGroupRef}>
                                                    <option value="">Select anyone WA Group</option>
                                                    {
                                                        waGroups.map(group => {
                                                            return <option value={`${group.groupId}`} key={`${group.groupId}`}>{group.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                )

                            }

                            <div>
                                {
                                    processing && (
                                        <p className='font-sans text-lg text-orange-500'>Please wait...</p>
                                    )
                                }
                            </div>
                            {
                                migration && (
                                    <div className='grid grid-cols-1 p-2'>
                                        <GroupSelector handleWAAllGroups={handleWAAllGroups} setWaGroups={setWaGroups} createNewGroup={createNewGroup} />
                                    </div>
                                )
                            }

                            <div className='flex justify-end my-2 space-x-2 w-full'>
                                {
                                    !deleteProcessing ? (
                                        <button type='button' className='px-2 py-1 bg-red-600 text-white rounded-lg' onClick={handleDeleteSelectedContacts}>Delete</button>
                                    ) : (
                                        <button type='button' className='px-2 py-1 bg-red-600 text-white rounded-lg' disabled>Wait...</button>
                                    )
                                }

                                <button type='button' className='px-2 py-1 bg-yellow-600 text-white rounded-lg' onClick={handleCancel}>Cancel</button>

                                {
                                    !migration && (
                                        <button type='button' className='px-2 py-1 bg-orange-300 text-white rounded-lg' disabled={selectedDevice === ""} onClick={() => setMigration(!migration)}>
                                            {
                                                selectedDevice === "" ? <span>Select device</span> : <span> Migrate</span>
                                            }
                                        </button>
                                    )
                                }


                                {
                                    waGroups &&
                                    waGroups.length > 0 &&
                                    selectedDevice !== "" &&
                                    selectedContacts &&
                                    selectedContacts.length > 0 &&
                                    selectedGroup !== "Select anyone WA Group" &&
                                    (
                                        <button type='button' className='flex justify-center items-center text-xs px-2 py-1 bg-pink-600 text-white rounded-lg space-x-2' onClick={handleTransferContacts}>
                                            {
                                                transferProcessing ?
                                                    (
                                                        <ThemeProvider theme={theme}>
                                                            <CircularProgress size={14} color="primary" />
                                                        </ThemeProvider>
                                                    ) : (
                                                        <CopyPlus />
                                                    )
                                            }

                                            <span>Transfer Contacts</span>

                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default Group