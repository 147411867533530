import React, { useCallback, useEffect, useState } from 'react';
import useGoogleSheet from '../hook/useGoogleSheet';
import { Add, Remove } from '@mui/icons-material';

// Component for selecting a sheet
function SheetSelector({ sheetNames, onSelect }) {
    return (
        <select onChange={(e) => onSelect(e.target.value)}>
            <option>Select a Page</option>
            {sheetNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
            ))}
        </select>
    );
}

// Component for selecting a header
function HeaderSelector({ columnHeaders, onSelect, mapColumn }) {
    return (
        <select onChange={(e) => onSelect(e.target.value, mapColumn)}>
            <option>Map a Column</option>
            {columnHeaders.map((header, index) => (
                <option key={index} value={header}>{header}</option>
            ))}
        </select>
    );
}

// Main Component
function GoogleSheetHandler({ setExcelColumnValues }) {
    const {
        sheetUrl,
        setSheetUrl,
        sheetNames,
        columnHeaders,
        columnValues,
        fetchSheetNames,
        fetchColumnHeaders,
        fetchColumnValues,
        processing
    } = useGoogleSheet();

    setExcelColumnValues(columnValues);

    return (
        <div className="grid grid-cols-1 my-4 w-full">
            <div className='grid grid-cols-6 gap-2'>
                <div className='col-span-5'>
                    <input
                        type="text"
                        value={sheetUrl || ''} // Ensure value isn't undefined
                        onChange={(e) => setSheetUrl(e.target.value)}
                        placeholder="paste google sheet url here"
                    />
                </div>

                <div className='col-span-1'>
                    {!processing && (<button className='rounded-full bg-blue-400 text-white text-xs px-2 py-1 w-full' onClick={fetchSheetNames}>Sync</button>)}
                    {processing && (<button className='rounded-full bg-blue-400 text-white text-xs px-2 py-1 w-full' disabled>Wait...</button>)}
                </div>
            </div>

            {sheetNames.length > 0 && (
                <div className="grid grid-cols-1 my-4">
                    <div className="grid grid-cols-2 my-4">
                        <div>
                            <p className="text-md font-thin text-gray-800 mb-2">
                                SELECT PAGE:
                            </p>
                        </div>
                        <div>
                            <SheetSelector sheetNames={sheetNames} onSelect={fetchColumnHeaders} />
                        </div>
                    </div>

                    {columnHeaders.length > 0 && (
                        <>
                            <div>
                                <div className="grid grid-cols-2 my-2">
                                    <label className="text-md uppercase font-thin text-gray-800">Name:</label>
                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={fetchColumnValues} mapColumn="name" />
                                </div>

                                <div className="grid grid-cols-2 my-2">
                                    <label className="text-md uppercase font-thin text-gray-800">Phone:</label>
                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={fetchColumnValues} mapColumn="phone" />
                                </div>

                                <div className="grid grid-cols-2 my-2">
                                    <label className="text-md uppercase font-thin text-gray-800">Message:</label>
                                    <HeaderSelector columnHeaders={columnHeaders} onSelect={fetchColumnValues} mapColumn="message" />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default GoogleSheetHandler;
