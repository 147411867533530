import React, { useEffect, useState } from "react";

import Alerts from "../../components/Alerts.jsx"
import { Link } from "react-router-dom";
import axios from "axios";
import { gql, useLazyQuery } from "@apollo/client";
import { Key } from "lucide-react";

const domain = window.location.host;

const USER_GET = gql`
  query($email: String!){
    userByEmail(email: $email) {
      id
      uid
    }
}
`;

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [isPending, setIsPending] = useState(false);


  const [getUser, { loading, error: userError, data }] = useLazyQuery(USER_GET);

  const filterText = (str) => {
    let txt = str
      .replaceAll(/Firebase:|Error|Auth/gi, "")
      .replace(/[^a-zA-Z ]/gi, " ")
      .trim();
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const handleInputForm = (e) => {
    setEmail(e.target.value);
  };

  const handleReset = () => {

    let data = JSON.stringify({
      "subject": "Forget Password",
      "fromEmail": "info@mrkgroups.com",
      "toEmail": email,
      "html": `<!doctype html>
    <html lang="en-US">

    <head>
      <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
      <title>Reset Password Email Template</title>
      <meta name="description" content="Reset Password Email Template.">
      <style type="text/css">
        a:hover {
          text-decoration: underline !important;
        }
      </style>
    </head>

    <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
      <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8" style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
          <td>
            <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td style="height:80px;">&nbsp;</td>
              </tr>
              <tr>
                <td style="text-align:center;">
                  <a href="https://mrkgroups.com/" title="logo" target="_blank">
                    <img width="60" src="https://mrkgroups.com/static/media/logo.139b227eb1fd0aa6510e.png" title="logo" alt="logo">
                  </a>
                </td>
              </tr>
              <tr>
                <td style="height:20px;">&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0" style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                    <tr>
                      <td style="height:40px;">&nbsp;</td>
                    </tr>
                    <tr>
                      <td style="padding:0 35px;">
                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You have
                          requested to reset your password</h1>
                        <span style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                        <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                          A unique link to reset your
                          password has been generated for you. To reset your password, click the
                          following link and follow the instructions.
                        </p>
                        <a href="https://mrkgroups.com/changepassword?uid=${userProfile?.uid}&fid=${userProfile?.id}" style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Reset
                          Password</a>
                      </td>
                    </tr>
                    <tr>
                      <td style="height:40px;">&nbsp;</td>
                    </tr>
                  </table>
                </td>
              <tr>
                <td style="height:20px;">&nbsp;</td>
              </tr>
              <tr>
                <td style="text-align:center;">
                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${domain}</strong></p>
                </td>
              </tr>
              <tr>
                <td style="height:80px;">&nbsp;</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>

    </html>`,
      "attachments": []
    });

    let config = {
      method: 'post',
      url: `https://mrkgroups.com/mail/sendmail`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        setIsPending(false);
        setSuccess("Reset mail sent successfully");
      })
      .catch((error) => {
        console.log(error);
        setIsPending(false);
        setError(error.message);
      });
  };

  const handleResetPassword =
    async () => {

      try {
        setIsPending(true);
        await getUser({ variables: { email: email } })

      } catch (error) {
        setIsPending(false);

      }

    }


  useEffect(() => {
    let userProfile = null;
    if (data) {
      userProfile = data.userByEmail;
      setUserProfile(userProfile);
    }
  }, [data, loading]);

  useEffect(() => {
    if (userProfile) {
      handleReset();
    }

  }, [userProfile])

  useEffect(() => {
    if (userError?.graphQLErrors[0]?.message.includes("Cannot")) {
      setIsPending(false);
      setError("Email not found")
    }

  }, [userError])

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("")
    }, 5000)
  }, [userError, success])


  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-6">
          <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center">
            <Key />
          </div>
        </div>
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Forgot password?
        </h2>
        <p className="text-center text-gray-600 mb-6">
          No worries, we’ll send you reset instructions.
        </p>
        <div>
          <div className="grid grid-cols-1 items-center justify-center">
            {
              success ? (
                <Alerts type="Success">{filterText(success)}</Alerts>

              ) : (
                <Alerts type="Error">{filterText(error)}</Alerts>
              )
            }
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={handleInputForm}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your email"
            />
          </div>
          {
            !isPending && (
              <button
                onClick={handleResetPassword}
                className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors"
              >
                Reset
              </button>)
          }
          {
            isPending && (
              <button disabled
                className="w-full bg-purple-400 text-white font-bold py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors"
              >
                Wait...
              </button>
            )
          }
        </div>
        <div className="text-center mt-4">
          <Link to="/login" className="text-blue-600 hover:underline">
            Back to log in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
