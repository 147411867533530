import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import GoogleSheetEventHandler from "../../components/GoogleSheetEventHandler";
import Container from "../../components/Container";
import SheetRecords from "../sheet/SheetRecords";

const DEVICE_GET = gql`
  query($uid: String!){
      devicesByUID(uid: $uid) {
          id
          deviceId
          deviceName
          uid
          userId
          isConnected
          createdAt
          updatedAt
      }
}`;

function TriggerMessage() {
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
    const [sentMessage, setSentMessage] = useState(0);
    const [totalMessage, setTotalMessage] = useState(0);

    const [excelColumnValues, setExcelColumnValues] = useState(null)
    const [devices, setDevices] = useState([]);
    const [excelSheetValues, setExcelSheetValues] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [mapColumnValues, setMapColumnValues] = useState(null)
    const [columnHeader, setColumnHeader] = useState(null)

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const selectedDeviceRef = useRef([]);
    const cursorRef = useRef(null);
    const phoneRef = useRef(null);
    const messageRef = useRef(null);
    const dateTimeRef = useRef({});
    const fileRef = useRef(null);
    const [sheetUrl, setSheetUrl] = useState('');

    let targetColumn = 0

    const saveTrigger = async () => {
        if (
            selectedData &&
            selectedData.length > 0
        ) {

            const mapData = selectedData.map(obj => {
                const { id, ...rest } = obj;
                return {
                    ...rest,
                    "userId": parseInt(`${userInfo.id}`),
                    "deviceId": `${userInfo.uid}-${selectedDeviceRef.current[0]}`,
                    "deviceName": selectedDeviceRef.current[0],
                    "mapColumnValues": JSON.stringify(mapColumnValues)
                }
            })

            let data = JSON.stringify({
                query: `mutation($sheets: [SheetInput!]!) {
                sheetCreate(sheets: $sheets) {
                    message
                }
            }`,
                variables: { "sheets": mapData }
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://graphql.mrkgroups.com/',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setMessage(response.data.data.sheetCreate.message)
                })
                .catch((error) => {
                    console.log(error);
                });

            console.log("mapData", mapData)
            console.log("selectedData", selectedData)
            console.log("columnHeader", columnHeader)
            console.log("mapColumnValues", mapColumnValues)
        }

    }

    const removeDatabaseTrigger = async (sheetId) => {

        let data = JSON.stringify({
            query: `mutation($sheetIds: [ID!]!) {
                sheetDelete(sheetIds: $sheetIds) {
                    message
                    sheetErrors {
                    message
                    }
                }
            }`,
            variables: { "sheetIds": [sheetId] }
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://graphql.mrkgroups.com/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleSubmit = async () => {

        setIsClicked(true);

        const sheetId = extractSheetId(sheetUrl);

        if (sheetId) {

            //Remove all previous trigger from database by sheetId
            removeDatabaseTrigger(sheetId)

            //Remove all previous trigger from google sheet by sheetId

            let statusValue = mapColumnValues['status']
            targetColumn = columnHeader.findIndex(element => element === statusValue) + 1

            try {
                let data = JSON.stringify({
                    "sheetId": sheetId,
                    "targetColumn": targetColumn
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://mrkgroups.com/googlesheet/setup-trigger',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        // setMessage(response.data);
                        saveTrigger()
                        setIsClicked(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setError(`${error.message}`);
                        setIsClicked(false);
                    });

            } catch (error) {
                setError(`${error.message}`);
                setIsClicked(false);
            }
        } else {
            setError('Invalid Google Sheet URL');
            setIsClicked(false);
        }
    };

    const extractSheetId = (url) => {
        const regex = /\/d\/([a-zA-Z0-9-_]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const { refetch, data, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const handleSelectedDevice = (e) => {

        if (e.target.value) {
            selectedDeviceRef.current.push(e.target.value)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.value)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)
        } else {
            setIsDeviceSelected(false)
        }
    }

    const handleFileAttachmentReset = () => {
        if (fileRef.current) {
            fileRef.current.value = "";
            fileRef.current.type = "text";
            fileRef.current.type = "file";
        }
    };

    const resetAll = () => {

        setExcelColumnValues(null)

        if (cursorRef.current && cursorRef.current?.value) cursorRef.current.value = "";
        if (phoneRef.current && phoneRef.current.value) phoneRef.current.value = "";
        if (messageRef.current && messageRef.current.value) messageRef.current.value = "";
        if (dateTimeRef.current && dateTimeRef.current.value) dateTimeRef.current.value = "";
        if (fileRef.current && fileRef.current.value) fileRef.current.value = "";

        refetch({ uid: userInfo.uid })
    }

    useEffect(() => {
        if ((status !== "" || error !== "") && sentMessage === totalMessage) {
            setTimeout(() => {
                resetAll();
                handleFileAttachmentReset();
                setMessage("")
                setError("")
                setStatus("")
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, status, sentMessage]);

    useEffect(() => {
        let devicesByUID = "";
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (
            status !== ""
        ) {
            setTimeout(() => {
                resetAll()
            }, 5000);
        }

        if (
            error !== ""
        ) {
            setIsClicked(false)
        }

    }, [error, status]);

    useEffect(() => {
        let phones = []
        if (excelSheetValues && excelSheetValues.length > 0) {
            phones = excelSheetValues.map(value => value.phone)
            phoneRef.current.value = phones
            setTotalMessage(phoneRef.current.value.trim().split(",").length)
        }
    }, [excelSheetValues])

    return (
        <div className="flex w-screen h-screen">
            <Sidebar />
            <Container>
                <div className="grid grid-cols-12 gap-2 px-4 pt-8 pl-8 w-[80%]">
                    <div className="col-span-10 gap-2">
                        <div>
                            <p className="text-green-600  flex justify-center items-center py-4 text-xl font-semibold">SET TRIGGER</p>
                        </div>

                        <div className="flex flex-col space-y-4  overflow-y-scroll no-scrollbar">
                            <div className="grid grid-cols-1 gap-2">
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="">
                                        <p className="text-xs font-semibold">CONNECTED DEVICE : </p>
                                    </div>
                                    <div className="flex justify-start items-center" >
                                        <select onChange={handleSelectedDevice}>
                                            <option name="" value="" key="select device">Select device</option>
                                            {
                                                devices && devices.map((device) => {
                                                    return (
                                                        <option name={device.deviceName} key={device.deviceName} value={device.deviceName} >{device.deviceName}</option>
                                                    )
                                                }
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="grid grid-cols-1 my-4 w-full"
                            >
                                <div className="grid grid-cols-1 mt-4">
                                    <GoogleSheetEventHandler
                                        setExcelColumnValues={setExcelColumnValues}
                                        userInfo={userInfo}
                                        setSelectedData={setSelectedData}
                                        setSelectedSheetUrl={setSheetUrl}
                                        setMapColumnValues={setMapColumnValues}
                                        setColumnHeader={setColumnHeader}
                                    />
                                    <div className='flex justify-end my-4 w-full'>
                                        {!isClicked ? (
                                            <button className='w-[160px] rounded-full bg-blue-500 disabled:bg-blue-200 text-white px-4 py-1 text-xs' type="submit" onClick={handleSubmit} disabled={!isDeviceSelected}>
                                                {isDeviceSelected ? "Save" : "Select device"}
                                            </button>
                                        ) : (
                                            <button className='w-[200px] rounded-full bg-blue-300 text-white px-4 py-1 text-xs ' type="submit" disabled>Wait...</button>
                                        )}
                                    </div>
                                </div>

                                <div className="grid-cols-1 w-full">
                                    <div className="my-2">
                                        {status !== "" ? (
                                            <p className="text-center text-xl tracking-normal text-green-500 ">
                                                {status}
                                            </p>
                                        ) : error !== "" ? (
                                            <p className="text-center text-xl tracking-normal text-red-500">
                                                {error}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="my-2">
                                        {message !== "" ? (
                                            <p className="text-center text-xl tracking-normal text-green-500 ">
                                                {message}
                                            </p>
                                        ) : error !== "" ? (
                                            <p className="text-center text-xl tracking-normal text-red-500">
                                                {error}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className="text-green-600  flex justify-center items-center py-4 text-xl font-semibold">TRIGGER HISTORY</p>
                        </div>
                        <div className="grid grid-cols-1 gap-2 my-4">
                            <SheetRecords />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default TriggerMessage;
