import React, { useContext, useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Alerts from "../../components/Alerts";
import axios from "axios";
import illustration from './illustration.png'
import { Google } from '@mui/icons-material';
import Logo from "./logo.png"
import { AuthContext } from "../../context/AuthContext";

const domain = window.location.host;

const Login = () => {
  const navigate = useNavigate()
  const { dispatch } = useContext(AuthContext);

  const [error, setError] = useState("");

  const [userProfile, setUserProfile] = useState(null);
  const [isPending, setIsPending] = useState(false)

  const [visibility, setVisibility] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleEmailChange = (e) => {
    e.preventDefault();
    emailRef.current = e.target.value;
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    passwordRef.current = e.target.value;
  };

  const handleVerify = () => {

    let data = JSON.stringify({
      "subject": "Account Verify",
      "fromEmail": "info@mrkgroups.com",
      "toEmail": emailRef.current,
      "html": `
<!doctype html>
<html lang="en-US">
<head>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <title>Account verify Email </title>
    <meta name="description" content="Account verify Email .">
    <style type="text/css">
        a:hover {text-decoration: underline !important;}
    </style>
</head>

<body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
    <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
        style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
            <td>
                <table style="background-color: #f2f3f8; max-width:670px; margin:0 auto;" width="100%" border="0"
                    align="center" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <a href="https://mrkgroups.com/" title="logo" target="_blank">
                            <img width="60" src="https://mrkgroups.com/static/media/logo.139b227eb1fd0aa6510e.png" title="logo" alt="logo">
                          </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                style="max-width:670px; background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="padding:0 35px;">
                                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Get started
                                        </h1>
                                        <p style="font-size:15px; color:#455056; margin:8px 0 0; line-height:24px;">
                                            Your account has been created on the MRK. Below are your account verify link, 
                                        </p>

                                        <br/>
                                    </td>
                                </tr>
                                <tr>
                                  <td style="padding:0 35px;">
                                    <a href="https://mrkgroups.com/verifyaccount?uid=${userProfile?.uid}&fid=${userProfile?.id}"
                                    style="background:#20e277;text-decoration:none !important; display:inline-block; font-weight:500; margin-top:24px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify your Account</a>
                                  </td>
                                </tr>
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${domain}</strong> </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

</html>`,
      "attachments": []
    });

    let config = {
      method: 'post',
      url: `https://mrkgroups.com/mail/sendmail`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        setError("Check your mail box after one or two mins to verify your account")
        setIsPending(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
        setIsPending(false);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setIsPending(true);

    if (emailRef.current && passwordRef.current) {
      let data = JSON.stringify({
        query: `query ($email: String!, $password: String!) {
          login(email: $email, password: $password) {
            id
            uid
            email
            planType
            userType
            resellerId
            isVerified
          }
        }`,
        variables: {
          email: emailRef.current,
          password: passwordRef.current,
        },
      });

      let config = {
        method: "post",
        url: `https://graphql.mrkgroups.com`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          let {
            data: { login: user },
          } = response.data;

          if (user) {
            setUserProfile(user);
          } else {
            setError("Check email or password")
            setIsPending(false);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setIsPending(false);
        });
    }
  };

  useEffect(() => {
    if (userProfile) {

      //CHECK IS A DEMO ACCOUNT
      if (userProfile.planType.trim() === "DEMO") {

        //CHECK IS USER VERIFIED
        if (!userProfile.isVerified) {

          if (userProfile) handleVerify();
          return;
        }
      }

      if (userProfile && userProfile.userType === "User") {
        dispatch({ type: "LOGIN", payload: userProfile });

        if (userProfile && Object.entries(userProfile).length > 0)
          localStorage.setItem("user", JSON.stringify(userProfile));

        navigate("/dashboard");
      } else {
        setIsPending(false);
        setError("Check email and password");
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  return (
    <div className="min-h-screen flex">
      {/* Left side - Login Form */}
      <div className="w-1/2 bg-blue-50 flex justify-center items-center p-8 relative">
        {/* Illustration Image */}
        <div className="text-center">
          <img
            src={illustration} // Replace with your image
            alt="Illustration"
            className="mx-auto mb-6"
          />
          {/* <div className="text-right absolute top-8 right-8">
            <h3 className="text-lg font-bold">New Update Available</h3>
            <p className="text-gray-500">We have added some new awesome features</p>
            <a
              href="/learn-more"
              className="text-blue-600 hover:underline mt-2 inline-block"
            >
              Learn More
            </a>
          </div> */}
        </div>
      </div>

      {/* Right side - Image and Update Info */}

      <div className="w-1/2 bg-white flex flex-col justify-center items-center p-8">
        <div className="w-3/4 max-w-md">
          {/* Logo */}
          <div className="text-center mb-6">
            <img
              src={Logo} // Replace this with your logo
              alt="MRK Group"
              className="mx-auto"
              style={{ height: '50px' }}
            />
          </div>
          {/* Welcome Text */}
          <h2 className="text-3xl font-bold text-center text-green-600 mb-6 uppercase">Welcome Back</h2>

          {/* Google Login Button */}
          <button className="w-full bg-blue-500 text-white border border-gray-300 py-2 rounded-lg flex items-center justify-center gap-2 mb-6">
            <Google />
            <span>Log in with Google</span>
          </button>

          {/* Divider */}
          <div className="flex items-center mb-4">
            <hr className="flex-grow border-gray-300" />
            <span className="mx-2 text-gray-400">OR LOGIN WITH EMAIL</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          {/* Login Form */}
          <form onSubmit={handleLogin}>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Your Email"
              ref={emailRef}
              onChange={handleEmailChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg mb-4"
            />
            <div className="relative mt-1">
              <input
                id="password"
                name="password"
                type={visibility ? "text" : "password"}
                ref={passwordRef}
                onChange={handlePasswordChange}
                placeholder="Password"
                required
                className="w-full p-3 border border-gray-300 rounded-lg mb-4"
              />
              {visibility ? (
                <VisibilityOffIcon
                  className="absolute top-3 right-2 cursor-pointer"
                  onClick={() => handleVisibility()}
                />
              ) : (
                <VisibilityIcon
                  className="absolute top-3 right-2 cursor-pointer"
                  onClick={() => handleVisibility()}
                />
              )}
            </div>
            <div className="flex justify-between items-center mb-6">
              <label className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span>Keep me logged in</span>
              </label>
              <Link to="/resetpassword" className="text-blue-600 hover:underline">
                Forgot password?
              </Link>
            </div>

            {/* Login Button */}
            <div className="flex w-full flex-col items-center space-y-2">
              {!isPending && (
                <button
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Log In
                </button>
              )}
              {isPending && (
                <button
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  disabled
                >
                  Wait....
                </button>
              )}
              {error && <Alerts alertType="Error">{error}</Alerts>}
            </div>
          </form>

          {/* Signup Link */}
          <p className="text-center mt-6">
            Don’t have an account yet?{' '}
            <Link to="/signup" className="text-blue-600 hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
