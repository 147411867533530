import React from 'react';
import useTemplate from './useTemplate';
import Container from '../../components/Container';
import Sidebar from '../../components/Sidebar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DataTable from '../../components/DataTable';
import Alerts from '../../components/Alerts'
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";

const Template = () => {

    const { formData, handleChange, handleSave, handleCancel, records, error, handleEdit, handleDelete, isPending } = useTemplate();

    const columns = [
        { field: "createdAt", headerName: "Date Time", minWidth: 250, align: "left" },
        { field: "templateName", headerName: " Name", minWidth: 180, align: "left" },
        { field: "category", headerName: "Category", minWidth: 150, align: "left" },
        { field: "language", headerName: "Language", minWidth: 150, align: "left" },
        { field: "body", headerName: "Message", minWidth: 350, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={< Edit />}
                    label="Edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteOutlineIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    // Handler for Quill input
    const handleQuillChange = (value) => {
        handleChange({
            target: {
                name: 'body',
                value: value,
            },
        });
    };

    return (
        <div className="flex w-screen h-screen ">
            <Sidebar />
            <Container>
                <div className="grid grid-cols-12 gap-2  px-4 pt-8 pl-8 h-screen">
                    <div className='col-span-8'>

                        {/* Form Section */}
                        <>
                            {
                                error && (
                                    <Alerts alertType="Error">{error}</Alerts>
                                )
                            }
                            <div className="bg-white rounded-lg shadow-md p-4">
                                <h2 className="text-xl font-semibold mb-4 text-green-600 text-center">CREATE TEMPLATE</h2>
                                <div className="mb-4">
                                    <label className="block text-gray-700 mb-1"> Name</label>
                                    <input
                                        type="text"
                                        name="templateName"
                                        value={formData.templateName}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 mb-1">Category</label>
                                    <select
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    >
                                        <option value="Marketing">Marketing</option>
                                        <option value="Support">Support</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 mb-1">Language</label>
                                    <select
                                        name="language"
                                        value={formData.language}
                                        onChange={handleChange}
                                        className="w-full border border-gray-300 rounded-lg p-2"
                                    >
                                        <option value="English (US)">English (US)</option>
                                        <option value="English (UK)">English (UK)</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 mb-1">Message</label>
                                    <ReactQuill
                                        value={formData.body}
                                        onChange={handleQuillChange}
                                        className="w-full border border-gray-300 rounded-lg"
                                        theme="snow"
                                    />
                                </div>

                                <div className="flex justify-end space-x-4">
                                    <button
                                        onClick={handleCancel}
                                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSave}
                                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div>
                                {
                                    records && records.length >= 0 ? (
                                        <DataTable columns={columns} data={records} />
                                    ) : (
                                        <p className="text-xl font-semibold text-red-500 ">
                                            no records found
                                        </p>
                                    )
                                }
                            </div>
                        </>
                    </div>

                    <div className="col-span-4 ">
                        {/* Preview Section */}
                        <div className="p-4 bg-white rounded-lg shadow-md">
                            <h3 className="text-lg font-semibold mb-4">Preview</h3>
                            <div className="border border-gray-300 rounded-lg p-4 bg-green-100 h-full">
                                <div className="bg-white p-4 rounded-lg shadow-lg">
                                    <p className="mb-2">
                                        <strong>Hi {formData.name || '{{name}}'} 👋,</strong>
                                    </p>
                                    {/* Displaying the content of the 'body' field with formatting */}
                                    <div dangerouslySetInnerHTML={{ __html: formData.body || 'Thank you for your message. How can I help you today?' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Template;
