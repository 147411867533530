import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Qrcode from "./pages/qrcode/Qrcode";
import Api from "./pages/api/Api";
import Plugin from "./pages/plugins/Plugin";
import Plans from "./pages/plans/Plans";
import Delivery from "./pages/report/Delivery";
import SingleMessage from "./pages/message/SingleMessage";
import MultiMessage from "./pages/message/MultiMessage.jsx";
import Contact from "./pages/contacts/Contact"
import Signup from "./pages/signup/Signup";
import Profile from "./pages/profile/Profile";
import Privacy from "./pages/privacy/Privacy";
import Order from "./pages/order/Order";
import ReturnPolicy from "./pages/returnpolicy/ReturnPolicy";
import Policy from "./pages/privacy/Policy";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import PasswordChange from "./pages/resetpassword/PasswordChange";
import VerifyAccount from "./pages/verify/VerifyAccount";
import Reminder from "./pages/reminder/Reminder";
import NotFoundPage from "./pages/404/NotFoundPage";
import Device from "./pages/subuser/Device";
import GroupManagement from "./pages/message/GroupManagement";
import Aggrement from "./pages/privacy/Aggrement";
import GroupMessage from "./pages/message/GroupMessage.jsx";
import EQRCode from "./pages/qrcode/EQRCode";
import WebHook from "./pages/webhook/WebHook.jsx";
import GroupInMessage from "./pages/groups/GroupInMessage.jsx";
import GroupInMessageView from "./pages/groups/GroupInMessageView.jsx";
import Schedule from "./pages/report/Schedule.jsx";
import SheetMessage from "./pages/message/SheetMessage.jsx";
import TriggerMessage from "./pages/message/TriggerMessage.jsx";
import Busy from "./pages/busy/Busy.jsx";
import Template from "./pages/template/Template.jsx";
import BulkMessage from "./pages/message/BulkMessage copy.jsx";


function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="signup" element={<Signup />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="addDevice"
              element={
                <RequireAuth>
                  <Device />
                </RequireAuth>
              }
            />
            <Route
              path="qrcode"
              element={
                <RequireAuth>
                  <Qrcode />
                </RequireAuth>
              }
            />
            <Route
              path="contacts"
              element={
                <RequireAuth>
                  <Contact />
                </RequireAuth>
              }
            />
            <Route
              path="reminder"
              element={
                <RequireAuth>
                  <Reminder />
                </RequireAuth>
              }
            />
            <Route
              path="singlemessage"
              element={
                <RequireAuth>
                  <SingleMessage />
                </RequireAuth>
              }
            />
            <Route
              path="bulkmessage"
              element={
                <RequireAuth>
                  <BulkMessage />
                </RequireAuth>
              }
            />
            <Route
              path="multimessage"
              element={
                <RequireAuth>
                  <MultiMessage />
                </RequireAuth>
              }
            />
            <Route
              path="sheetmessage"
              element={
                <RequireAuth>
                  <SheetMessage />
                </RequireAuth>
              }
            />
            <Route
              path="groupmessage"
              element={
                <RequireAuth>
                  <GroupMessage />
                </RequireAuth>
              }
            />
            <Route
              path="manageGroup"
              element={
                <RequireAuth>
                  <GroupManagement />
                </RequireAuth>
              }
            />
            <Route
              path="template"
              element={
                <RequireAuth>
                  <Template />
                </RequireAuth>
              }
            />
            <Route
              path="triggermessage"
              element={
                <RequireAuth>
                  <TriggerMessage />
                </RequireAuth>
              }
            />
            <Route
              path="api"
              element={
                <RequireAuth>
                  <Api />
                </RequireAuth>
              }
            />
            <Route
              path="webhook"
              element={
                <RequireAuth>
                  <WebHook />
                </RequireAuth>
              }
            />
            <Route
              path="groupInMessage"
              element={
                <RequireAuth>
                  <GroupInMessage />
                </RequireAuth>
              }
            />
            <Route
              path="groupInMessageView"
              element={
                <RequireAuth>
                  <GroupInMessageView />
                </RequireAuth>
              }
            />
            <Route
              path="plugins"
              element={
                <RequireAuth>
                  <Plugin />
                </RequireAuth>
              }
            />
            <Route
              path="busy"
              element={
                <RequireAuth>
                  <Busy />
                </RequireAuth>
              }
            />
            <Route
              path="plans"
              element={
                <RequireAuth>
                  <Plans />
                </RequireAuth>
              }
            />
            <Route
              path="profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route path="report">
              <Route
                path="delivery"
                element={
                  <RequireAuth>
                    <Delivery />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="report">
              <Route
                path="schedule"
                element={
                  <RequireAuth>
                    <Schedule />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
          <Route
            path="order"
            element={
              <RequireAuth>
                <Order />
              </RequireAuth>
            }
          />
          <Route
            path="getQR"
            element={

              <EQRCode />

            }
          />
          <Route path="returnpolicy" element={<ReturnPolicy />} />
          <Route path="privacypolicy" element={<Policy />} />
          <Route path="aggrement" element={<Aggrement />} />
          <Route path="changepassword" element={<PasswordChange />} />
          <Route path="verifyaccount" element={<VerifyAccount />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
