import React, { useState, useEffect } from "react";

import Sidebar from '../../components/Sidebar'
import profile from "./profile.png";
import { gql, useMutation, useQuery } from "@apollo/client";

import moment from "moment";
import Container from "../../components/Container";
import Location from "../../components/Location";
import Alerts from "../../components/Alerts";

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      uid
      name
      email
      mobile
      password
      address
      country
      doj
      planType
      userType
      expireInDays
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

const USER_UPDATE = gql`
  mutation ($userId: ID!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userErrors {
        message
      }
      user {
        name
      }
    }
  }
`;

const Profile = () => {

  // PREVIOUS CODE START
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [processing, setProcessing] = useState(false)

  let { uid } = JSON.parse(localStorage.getItem("user"));

  const [profile, setProfile] = useState({});

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true)
    console.log(userProfile);

    if (userProfile.newPassword === userProfile.confirmPassword) {

      updateUser({
        variables: {
          userId: userProfile.uid,
          user: {
            password: userProfile.newPassword,
          },
        },
        onCompleted: () => {
          setProcessing(false)
          setSuccess("Passowd is updated successfully!")
        }
      });
    } else {
      setError("Password not matched!")
      setProcessing(false)
    }
  };

  //GRAPHQL USER QUERY START

  const { data, loading } = useQuery(USER_GET, {
    variables: { uid: uid },
  });

  //GRAPHQL USER QUERY END

  //GRAPHQL USER CHANGE PASSWORD START
  const [
    updateUser
  ] = useMutation(USER_UPDATE, {
    refetchQueries: [
      USER_GET,
      {
        variables: { uid: uid },
      },
    ],
  });
  //GRAPHQL USER CHANGE PASSWORD END
  useEffect(() => {
    if (userProfile) {
      setProfile({
        location: userProfile.country ? userProfile.country : "India",
        notifications: {
          orderConfirmation: true,
          orderStatusChanged: false,
          orderDelivered: true,
          emailNotification: false
        }
      })
    }
  }, [userProfile])

  useEffect(() => {
    let userProfile = null;
    if (data) {
      userProfile = data.user;
      setUserProfile(userProfile);
    }
  }, [data, loading]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 1000);
  }, [error, success]);
  // PREVIOUS CODE END

  return (
    <div className="flex w-screen h-screen bg-white">
      <Sidebar />
      <Container>
        {
          userProfile && (
            <div className="p-6 max-w-4xl">
              <div className="grid grid-cols-1 justify-center">
                {
                  error && (
                    <Alerts alertType="Error">{error}</Alerts>
                  )
                }
                {
                  error && (
                    <Alerts alertType="Success">{success}</Alerts>
                  )
                }

              </div>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {/* Edit Profile Section */}
                  <div>
                    <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
                    <div className="mb-4">
                      <label className="block text-gray-700">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        value={userProfile.name || ""}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <Location profile={profile} handleChange={handleChange} initValue={userProfile.country} />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={userProfile.email || ""}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        value={userProfile.mobile || ""}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Address</label>
                      <input
                        type="text"
                        name="address"
                        value={userProfile.address || ""}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">D.O.J</label>
                      <input
                        type="text"
                        name="doj"
                        value={moment(Number(userProfile.doj)).format('DD/MM/YYYY') || ""}
                        readOnly
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                  </div>

                  {/* Plan Type */}
                  {/* <div>
                    <h2 className="text-2xl font-bold mb-4">Plan Details</h2>
                    <div className="mb-4">
                      <label className="block text-gray-700">Plan Type</label>
                      <input
                        type="text"
                        name="planType"
                        value={userProfile.planType || ""}
                        readOnly
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">User Type</label>
                      <input
                        type="text"
                        name="userType"
                        value={userProfile.userType || ""}
                        readOnly
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Expire In Days</label>
                      <input
                        type="text"
                        name="expireInDays"
                        value={userProfile.expireInDays || ""}
                        readOnly
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Total Messages</label>
                      <input
                        type="text"
                        name="totalMessages"
                        value={userProfile.totalMessages || ""}
                        readOnly
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Total Messages Sent</label>
                      <input
                        type="text"
                        name="totalMessagesSent"
                        value={userProfile.totalMessagesSent || ""}
                        readOnly
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                  </div> */}

                  {/* Change Password Section */}
                  <div>
                    <h2 className="text-2xl font-bold mb-4">Change Password</h2>

                    <div className="mb-4 relative">
                      <label className="block text-gray-700">New Password</label>
                      <input
                        type={showNewPassword ? "text" : "password"}
                        name="newPassword"
                        value={userProfile.newPassword || ""}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm pr-10"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-1/2  px-3 text-gray-600"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? "🙈" : "👁️"}
                      </button>
                    </div>

                    <div className="mb-4 relative">
                      <label className="block text-gray-700">Confirm Password</label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={userProfile.confirmPassword || ""}
                        onChange={handleChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm pr-10"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-1/2 px-3 text-gray-600"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? "🙈" : "👁️"}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Notifications Section */}

                {
                  // userProfile.notifications &&
                  // userProfile.notifications.orderConfirmation && (
                  //   <div className="mt-6">
                  //     <h2 className="text-2xl font-bold mb-4">Notifications</h2>
                  //     <div className="mb-4">
                  //       <label className="flex items-center">
                  //         <input
                  //           type="checkbox"
                  //           name="orderConfirmation"
                  //           checked={userProfile.notifications.orderConfirmation}
                  //           onChange={handleNotificationChange}
                  //           className="mr-2"
                  //         />
                  //         Order Confirmation
                  //       </label>
                  //       <p className="text-gray-600 ml-6">You will be notified when you orders any product.</p>
                  //     </div>
                  //     <div className="mb-4">
                  //       <label className="flex items-center">
                  //         <input
                  //           type="checkbox"
                  //           name="orderStatusChanged"
                  //           checked={userProfile.notifications.orderStatusChanged}
                  //           onChange={handleNotificationChange}
                  //           className="mr-2"
                  //         />
                  //         Order Status Changed
                  //       </label>
                  //       <p className="text-gray-600 ml-6">You will be notified when you makes changes to the order.</p>
                  //     </div>
                  //     <div className="mb-4">
                  //       <label className="flex items-center">
                  //         <input
                  //           type="checkbox"
                  //           name="orderDelivered"
                  //           checked={userProfile.notifications.orderDelivered}
                  //           onChange={handleNotificationChange}
                  //           className="mr-2"
                  //         />
                  //         Order Delivered
                  //       </label>
                  //       <p className="text-gray-600 ml-6">You will be notified once the order is delivered.</p>
                  //     </div>
                  //     <div className="mb-4">
                  //       <label className="flex items-center">
                  //         <input
                  //           type="checkbox"
                  //           name="emailNotification"
                  //           checked={userProfile.notifications.emailNotification}
                  //           onChange={handleNotificationChange}
                  //           className="mr-2"
                  //         />
                  //         Email Notification
                  //       </label>
                  //       <p className="text-gray-600 ml-6">Turn on email notifications to get updates through email.</p>
                  //     </div>
                  //   </div>
                  // )
                }



                {/* Save Button */}
                <div className="mt-6">
                  {
                    !processing ? (
                      <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-1 text-md rounded-md shadow hover:bg-blue-600"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="cancel"
                        disabled
                        className="bg-blue-300 text-white px-4 py-1 text-md rounded-md shadow "
                      >
                        Wait...
                      </button>
                    )
                  }
                </div>
              </form>
            </div>
          )
        }

      </Container>
    </div>

  );
};

export default Profile;
