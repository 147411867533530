import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import Paper from "@mui/material/Paper";

import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import Container from "../../components/Container";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { SearchOffOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const USER_GET = gql`
  query getUser($uid: String!) {
    user(uid: $uid) {
      id
      uid
      name
      email
      mobile
      password
      address
      country
      doj
      planType
      userType
      expireInDays
      totalMessages
      totalMessagesSent
      isConnected
    }
  }
`;

const GET_GROUP_IN_MESSAGE = gql`
query getGroupInMessages {
    groupInMessage {
      id
      groupName
      groupAdmin
      from
      to
      message
    }
}
`;

export default function GroupInMessage() {
    const navigate = useNavigate();

    const uid = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    const { data, error, loading } = useQuery(USER_GET, {
        variables: { uid: uid.uid },
    });

    const { data: groupInMessageData, error: groupInMessageError, loading: groupInMessageLoading } = useQuery(GET_GROUP_IN_MESSAGE);

    const [userProfile, setUserProfile] = useState("");

    const [messagesInData, setMessagesInData] = useState("");

    const columns = [
        { field: "id", headerName: "ID", minWidth: 50, align: "left" },
        { field: "groupName", headerName: "Group Name", minWidth: 170, align: "left" },
        { field: "groupAdmin", headerName: "Group Admin", minWidth: 170, align: "left" },
        { field: "from", headerName: "From", minWidth: 250, align: "left" },
        { field: "to", headerName: "To", minWidth: 300, align: "left" },
        { field: "message", headerName: "Message", minWidth: 170, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<SearchOffOutlined />}
                    label="View"
                    onClick={() => navigate(
                        "/groupInMessageView", { state: { ...params.row } }
                    )}
                />,
            ]
        },
    ];

    //Userplan Fetch End

    useEffect(() => {
        let userProfile = "";
        if (data) {
            userProfile = data.user;
            setUserProfile(userProfile);
        }
    }, [data, loading]);

    useEffect(() => {
        if (groupInMessageData) {

            setMessagesInData(groupInMessageData.groupInMessage);
        }
    }, [groupInMessageData, groupInMessageLoading]);

    return (
        <div className="flex w-screen h-screen bg-white">
            <Sidebar />
            <Container>
                <div>
                    <p className="text-green-600  flex justify-center items-center py-4 text-xl font-semibold">REPLIES HISTORY</p>
                </div>
                <div className="grid grid-cols-12 gap-2  p-4 pt-0 h-screen overflow-y-scroll no-scrollbar">
                    <div className="col-span-12">
                        {!groupInMessageLoading && (
                            <div className="mb-4">
                                <DataTable columns={columns} data={messagesInData} />
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}
