import React, { useState, useEffect } from "react";

import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import io from 'socket.io-client';
import wreloadqr from "./wreloadqr.png"

const socket = io(`https://apigully.com/`);

function EQRCode() {
    const [qrCode, setQRCode] = useState("");
    const [userProfile, setUserProfile] = useState({});
    const [retryClicked, setRetryClicked] = useState(false)

    const useQuery = () => new URLSearchParams(useLocation().search);

    let query = useQuery();

    const retryHandle = (e) => {
        e.preventDefault();
        setRetryClicked(true);
        setConnectionStatus("Please Wait...")
        if (
            userProfile &&
            userProfile.uid &&
            userProfile.id
        ) {

            socket.emit('AddDevice', {
                userId: Number(userProfile.id),
                id: userProfile.uid,
                uid: userProfile.uid,
                name: userProfile.deviceName,
            });
        }
    }

    //STEP II
    //SOCKET START
    const [connectionStatus, setConnectionStatus] = useState("")

    useEffect(() => {
        if (
            userProfile &&
            userProfile.uid &&
            userProfile.id
        ) {

            // socket.on('connect', () => {
            //     setConnectionStatus("Connected to the server");
            // });

            // socket.on('disconnect', () => {
            //     setConnectionStatus("Disconnected to the server")
            // });

            socket.on(`${userProfile.uid}-${userProfile.deviceName}`, (msg) => {
                if (msg.hasOwnProperty("qrCode")) {
                    setQRCode(msg.qrCode)
                    setConnectionStatus("QR Ready to Scan")
                    setRetryClicked(false);
                }

                switch (msg.status) {
                    case "notLogged":
                        if (qrCode === "") {
                            setConnectionStatus("Processing...");
                        } else {
                            setConnectionStatus("QR Ready to Scan");
                        }
                        break;
                    case "inValidUser":
                        setConnectionStatus("You are not a valid user :(")
                        setQRCode("")
                        break;
                    case "autocloseCalled":
                        setConnectionStatus("Retry")
                        setQRCode("")
                        break;
                    case "inChat":
                        setConnectionStatus("Your device is connected!!")
                        setQRCode("")
                        break;
                    case "CONNECTED":
                        setConnectionStatus("Your device is connected!!")
                        setQRCode("")
                        break;
                    case "qrReadError":
                        setConnectionStatus("Retry")
                        setQRCode("")
                        break;
                    case "browserClose":
                        setConnectionStatus("Retry")
                        setQRCode("")
                        break;
                    case "desconnectedMobile":
                        setConnectionStatus("Your device is disconnected!!")
                        setQRCode("")
                        break;
                    case "DISCONNECTED":
                        setConnectionStatus("Your device is disconnected!!")
                        setQRCode("")
                        break;

                    default:
                        break;
                }



            });

        }

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off(`${userProfile.uid}-${userProfile.deviceName}`);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    //SOCKET END

    useEffect(() => {
        if (
            userProfile &&
            userProfile.uid &&
            userProfile.id

        ) {
            socket.emit('AddDevice', {
                userId: userProfile.id,
                id: userProfile.uid,
                uid: userProfile.uid,
                name: userProfile.deviceName,
            });
        }
    }, [userProfile])

    //STEP I
    useEffect(() => {

        setConnectionStatus("Please Wait...")

        let userInfo = {
            "userId": query.get('id'),
            "id": query.get('id'),
            "uid": query.get('id'),
            "deviceName": query.get('deviceName'),
        };

        if (userInfo && userInfo.uid) setUserProfile(userInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex w-screen ">
            <div className="body w-64 flex-1 p-8 bg-white">

                <div
                    id="qrcode"
                    className="m-16 flex items-center justify-center p-8"
                ></div>

                {
                    (connectionStatus === "Retry" || connectionStatus === "Your device is disconnected!!") && (
                        <button onClick={retryHandle} disabled={retryClicked}>
                            <img src={wreloadqr} alt="retry" />
                        </button>
                    )
                }

                <div className="w-[339px] flex justify-center items-center">
                    {qrCode !== "" && (
                        <div>
                            <QRCode value={qrCode} />
                        </div>
                    )}
                </div>
                <div className="text-lg font-serif text-green-500 uppercase leading-3 tracking-wides my-4 w-[339px] text-center">{connectionStatus}</div>
                <div>
                    <span className="font-semibold tracking-widest font-2xl text-left text-red-400 mr-2">CAUTION:</span>
                    <span className="font-xl tracking-wider font-semibold mb-4 text-left text-blue-400">
                        Do not sign out from your account after device is
                        connected successfully
                    </span>
                </div>
            </div>
        </div>
    );
}

export default EQRCode;
