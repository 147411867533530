import React, { useState, useEffect } from "react";

import Sidebar from "../../components/Sidebar";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import socketIOClient from 'socket.io-client';
import wreloadqr from "./wreloadqr.png"
import { gql, useMutation } from "@apollo/client";

import moment from "moment-timezone";
import Container from "../../components/Container";
import { Menu, Settings } from "lucide-react";

const ENDPOINT = `https://apigully.com/`

const USER_DEVICE_STATUS = gql`
  mutation($deviceId: ID!, $device: DeviceInput!){
    deviceUpdate(deviceId: $deviceId, device: $device) {
      device {
        id
      }
    }
  }
`;

function Qrcode() {
  const [socket] = useState(() => socketIOClient(ENDPOINT));
  const [qrCode, setQRCode] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const location = useLocation();
  const [retryClicked, setRetryClicked] = useState(false)

  const retryHandle = (e) => {
    e.preventDefault();
    setRetryClicked(true);
    setConnectionStatus("Please Wait...")

    const room = `${userProfile.uid}-${userProfile.deviceName}`

    const message = {
      userId: Number(userProfile.id),
      id: userProfile.uid,
      uid: userProfile.uid,
      name: userProfile.deviceName,
    }

    if (
      userProfile &&
      userProfile.uid &&
      userProfile.id
    ) {
      socket.emit('AddDevice', { room, message });
    }
  }

  //STEP II
  //SOCKET START
  const [connectionStatus, setConnectionStatus] = useState("")
  const [updateUserDevice] = useMutation(USER_DEVICE_STATUS)

  useEffect(() => {

    if (
      userProfile &&
      userProfile.uid &&
      userProfile.id
    ) {

      socket.on('connect', () => {
        setConnectionStatus("Connecting to the server...");
      });

      socket.emit('joinRoom', `${userProfile.uid}-${userProfile.deviceName}`);

      socket.on(`${userProfile.uid}-${userProfile.deviceName}`, (msg) => {

        let deviceId = `${userProfile.uid}-${userProfile.deviceName}`

        if (msg.hasOwnProperty("qrCode")) {
          setQRCode(msg.qrCode)
          setConnectionStatus("QR Ready for Scan")
          setRetryClicked(false);
        } else {
          setConnectionStatus(msg.message);

          if (msg && msg.message.includes("is connected successfully")) {
            setQRCode("")

            updateUserDevice({
              variables: {
                "deviceId": deviceId,
                "device": {
                  "userId": Number(userProfile.id),
                  "uid": deviceId.split("-")[0]?.trim(),
                  "deviceId": deviceId,
                  "deviceName": deviceId.split("-")[1]?.trim(),
                  "isConnected": true,
                  "updatedAt": moment().tz("Asia/Kolkata").format()
                }
              }
            })
          }
        }

      });

    }

    socket.on('disconnect', () => {
      console.log("Disconnected to the server")
      setConnectionStatus("Disconnected to the server")
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off(`${userProfile.uid}-${userProfile.deviceName}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  //SOCKET END

  useEffect(() => {
    if (
      userProfile &&
      userProfile.uid &&
      userProfile.id

    ) {

      const room = `${userProfile.uid}-${userProfile.deviceName}`

      const message = {
        userId: Number(userProfile.id),
        id: userProfile.uid,
        uid: userProfile.uid,
        name: userProfile.deviceName,
      }

      socket.emit('AddDevice', { room, message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  //STEP I
  useEffect(() => {

    let userInfo = {
      ...location.state,
    };

    if (userInfo && userInfo.uid) setUserProfile(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-screen h-screen bg-white">
      <Sidebar />
      <Container>
        <div className="h-screen  p-4 flex items-center justify-center">
          <div className="w-full bg-white rounded-lg shadow-md p-6 grid grid-cols-12 h-[60%]">
            <div className="col-span-8 space-y-6  h-[60%]">
              <h1 className="text-2xl md:text-3xl font-bold text-green-600 uppercase text-left">Use WhatsApp on MessageAPI</h1>
              <ol className="list-decimal list-inside space-y-4 text-gray-600">
                <li>Open WhatsApp on your phone</li>
                <li className="flex items-center">
                  Tap Menu <Menu className="inline-block w-5 h-5 mx-1" /> on Android, or Settings{" "}
                  <Settings className="inline-block w-5 h-5 mx-1" /> on iPhone
                </li>
                <li>
                  Tap <span className="font-semibold">Linked devices</span> and then{" "}
                  <span className="font-semibold">Link a device</span>
                </li>
                <li>Point your phone at this screen to capture the QR code</li>
                <li>Do not sign out from your account after device is connected successfully</li>
              </ol>
            </div>
            <div className="col-span-4 h-[60%] justify-center self-center">
              <div>
                <div>

                  <div>
                    {
                      (
                        connectionStatus === "Retry" ||
                        connectionStatus === "Your device is disconnected!!"
                      ) && (
                        <button onClick={retryHandle} disabled={retryClicked}>
                          <img
                            src={wreloadqr}
                            alt="retry"
                            className="w-full max-w-[220px] h-auto"
                            width={220}
                            height={220}
                          />
                        </button>
                      )
                    }
                  </div>

                  <div>
                    {
                      qrCode !== "" && (
                        <div>
                          <QRCode
                            value={qrCode}
                            className="w-full max-w-[220px] h-auto"
                            width={220}
                            height={220}
                          />
                        </div>
                      )
                    }
                  </div>

                </div>
                <div className="flex flex-col justify-start gap-2">
                  <div className="text-lg font-serif text-green-500 leading-3 tracking-wides my-4 w-full">{connectionStatus}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Container>
    </div>
  );
}

export default Qrcode;
