import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function ExcelSheet({ data, setExcelSheetValues, setExcelSheetVisible }) {
    const [selectedContacts, setSelectedContacts] = useState([])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            editable: true
        },
        {
            field: 'phone',
            headerName: 'Phone',
            type: 'number',
            width: 120,
        },
        {
            field: 'message',
            headerName: 'message',
            type: 'string',
            width: 150,
        },

    ];

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => data.find((row) => row.id === id));
        setSelectedContacts(selectedRowsData);
    };

    const [snackbar, setSnackbar] = React.useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleCancel = () => {
        setExcelSheetVisible(false)
    }

    const handleSelectedContacts = () => {
        setExcelSheetValues(selectedContacts)
    }

    return (
        <div className='flex flex-col justify-center items-center w-full'>
            <div className='w-full'>
                {
                    data &&
                    data.length > 0 &&
                    (
                        <>
                            {/* <h1 className='text-center text-2xl text-gray-600 font-serif font-medium tracking-widest my-2'>SHEET CONTACTS</h1> */}
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    pageSize={5}
                                    editMode="row"
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}

                                />
                                {!!snackbar && (
                                    <Snackbar
                                        open
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        onClose={handleCloseSnackbar}
                                        autoHideDuration={5000}
                                    >
                                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                                    </Snackbar>
                                )}
                            </div>
                            <div className='flex justify-end my-2 space-x-2'>
                                <button type='button' className='px-2 py-1 bg-yellow-600 text-white rounded-lg w-[100px]' onClick={handleCancel}>Cancel</button>
                                <button type='button' className='px-2 py-1 bg-blue-600 text-white rounded-lg w-[100px]' onClick={handleSelectedContacts}>Get</button>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ExcelSheet