import axios from 'axios'

function useGroup() {
    const createGroup = async ({ id, deviceName, groupName, participants }) => {
        try {
            let data = JSON.stringify({
                "groupName": groupName,
                "participants": participants
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://apigully.com/group/createGroup/${id}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)
            return response.data
        } catch (error) {
            return error.message
        }
    }

    const updateGroup = () => {
        // 
    }

    const deletePhoneGroup = async ({ userId, deviceName, groupId }) => {

        try {
            let data = JSON.stringify({
                "groupId": groupId
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://apigully.com/group/deleteGroup/${userId}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)
            return response.data
        } catch (error) {
            return error.message
        }


    }

    const addGroupParticipant = async ({ id, deviceName, groupId, participantNumber }) => {

        try {
            let data = JSON.stringify({
                "groupId": groupId,
                "participantNumber": participantNumber
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://apigully.com/group/addParticipant/${id}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)
            return response.data
        } catch (error) {
            return error.message
        }
    }

    const updateGroupParticipant = () => {
        // 
    }

    const deleteGroupParticipant = async ({ id, deviceName, groupId, participantNumber }) => {
        try {
            let data = JSON.stringify({
                "groupId": groupId,
                "participantNumber": participantNumber
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://apigully.com/group/deleteParticipant/${id}/${deviceName}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let response = await axios.request(config)
            return response.data
        } catch (error) {
            return error.message
        }
    }

    const migrateGroupParticipant = () => {
        // 
    }

    return {
        createGroup,
        updateGroup,
        deletePhoneGroup,
        addGroupParticipant,
        updateGroupParticipant,
        deleteGroupParticipant,
        migrateGroupParticipant
    }
}

export default useGroup