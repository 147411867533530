import React, { useRef, useState } from 'react'
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Sidebar from '../../components/Sidebar'
import moment from "moment";
import { AddCircleRounded, Download, ImportContacts } from '@mui/icons-material';
import readXlsxFile from 'read-excel-file';
const axios = require('axios');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const columns = [
    { id: "groupName", label: "Group Name", minWidth: 130, align: "left" },
    { id: "from", label: "From", minWidth: 100, align: "center" },
    { id: "createdAt", label: "Created At", minWidth: 130, align: "left" },
    { id: "updatedAt", label: "Updated At", minWidth: 130, align: "left" },
];


function Contact() {

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //USE STATE START
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const fileImportData = useRef([]);
    const [isFileImportSelected, setIsFileImportSelected] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const phonesFile = useRef();
    const [error, setError] = useState("");
    const [phoneForm, setPhoneForm] = useState(false);
    const [fileForm, setFileForm] = useState(false);
    const [phoneFormData, setPhoneFormData] = useState({
        groupName: ""
    })
    //USE STATE END

    const createGroup = () => {
        //
    }

    const getPhoneContacts = () => {

        let config = {
            method: 'get',
            url: 'http://92.204.128.89:5001/contact/getAllContacts/16655968de804fbbb92de2574dfbaedb',
            headers: {}
        };

        axios(config)
            .then((response) => {
                setPhoneFormData(prevData => {
                    return {
                        ...prevData,
                        contacts: response.data
                    }
                })

                createGroup();
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const showHandler = (data) => {
        if (data === "Phone") {
            setPhoneForm(true);
            setFileForm(false);
        } else if (data === "File") {
            setFileForm(true);
            setPhoneForm(false);
        }
    }

    const phoneFormDataHandler = (e) => {
        setPhoneFormData(prevData => {
            return { ...prevData, [e.target.name]: e.target.value }
        })
    }

    const handlePhoneImport = (e) => {

        if (phoneFormData.groupName && phoneFormData.groupName.trim() !== "") {
            getPhoneContacts();
        } else {
            setError("This field is required")
        }
    }

    const handleFileImport = (e) => {

        if (phoneFormData.groupName && phoneFormData.groupName.trim() !== "") {
            //
            console.log(phoneFormData)
        } else {
            setError("This field is required")
        }
    }

    const handleExcelFile = async (e) => {
        setIsClicked(true);
        const map = {
            Name: "Name",
            Phone: "Phone",
            Message: "Message",
        };

        let file = phonesFile.current.files[0];

        let phones = [];

        await readXlsxFile(file, { map }).then(({ rows }) => {
            rows.forEach((row) => {
                phones.push(row.Phone.toString().trim());
                fileImportData.current.push(row);
            });

            if (fileImportData.current.length > 0) {
                setIsFileImportSelected(true);
            }
        });

        phones = phones.join(",");
        console.log(phones);
        setIsClicked(false);
        //SET Remain Msg to be send

    };

    return (
        <div className="flex w-screen ">
            <Sidebar />
            <div className="body w-full flex-1 bg-gray-200 p-8">
                <div>
                    <h1 className="mb-4 rounded bg-white p-2 text-center text-2xl font-semibold tracking-wider text-yellow-500 shadow-xl">
                        CONTACTS
                    </h1>
                    <div className='my-4'>
                        <div className='flex justify-between items-center'>
                            <div className='cursor-pointer bg-blue-300 text-white border-r-2 rounded-l-xl w-full text-center text-md font-semibold p-2'
                                onClick={() => showHandler("Phone")}
                            >
                                FROM PHONE <span className='ml-2 inline-block'><AddCircleRounded /></span>
                            </div>
                            <div className='cursor-pointer bg-blue-300 text-white border-l-2 rounded-r-xl w-full text-center text-md font-semibold p-2'
                                onClick={() => showHandler("File")}
                            >
                                FROM FILE <span className='ml-2 inline-block'><AddCircleRounded /></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        {phoneForm &&
                            <div className='flex justify-center w-full'>
                                <div className='w-1/3 p-2 rounded-xl'>
                                    <div>
                                        <label htmlFor="groupName" className='inline-block my-2 ml-2 text-gray-600 font-medium'>GROUP NAME</label>
                                        <input
                                            type="text"
                                            name='groupName'
                                            className='mx-2 rounded-lg w-full'
                                            onChange={phoneFormDataHandler}
                                            value={phoneFormData.groupName}
                                            placeholder="Enter group name"
                                            required />
                                        <div className='text-red-500 ml-2' hidden={error.trim() !== ""}>{error}</div>
                                    </div>
                                    <div className='text-right'>
                                        <button onClick={handlePhoneImport} className="p-2 bg-blue-500 text-white my-2 rounded-md shadow-md">
                                            Import <ImportContacts />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {fileForm &&
                            <div className='text-center'>
                                <div>
                                    <p className="inline-block text-xs font-bold uppercase leading-8 tracking-wider text-white">
                                        Download Sample Excel File
                                    </p>
                                    <a
                                        href="./assets/samples.xlsx"
                                        className="text-md ml-4 h-8 w-8 rounded-full bg-white font-semibold leading-8 text-blue-400"
                                    >
                                        <Download />
                                    </a>
                                </div>
                                <div className='flex justify-center w-full'>
                                    <div className='w-1/3 p-2 rounded-xl'>
                                        <div>
                                            <label htmlFor="groupName" className='inline-block my-2 ml-2 text-gray-600 font-medium'>GROUP NAME</label>
                                            <input
                                                type="text"
                                                name='groupName'
                                                className='mx-2 rounded-lg w-full'
                                                onChange={phoneFormDataHandler}
                                                value={phoneFormData.groupName}
                                                placeholder="Enter group name"
                                                required />
                                            <div className='text-red-500 ml-2' hidden={error.trim() !== ""}>{error}</div>
                                        </div>
                                        <div className='mt-2'>
                                            <input
                                                type="file"
                                                id="input"
                                                ref={phonesFile}
                                                onChange={handleExcelFile}
                                                accept=".xlsx"
                                                disabled={isClicked}
                                            />
                                            <div className='text-red-500 ml-2 mt-2' hidden={error.trim() !== ""}>{error}</div>
                                        </div>
                                        <div className='text-right'>
                                            <button onClick={handleFileImport} className="p-2 bg-blue-500 text-white my-2 rounded-md shadow-md">
                                                Import <ImportContacts />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 600 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.id === "createdAt"
                                                                    ? moment
                                                                        .unix(value / 1000)
                                                                        .format("DD-MM-YYYY hh:mm a")
                                                                    : column.format && typeof value === "number"
                                                                        ? column.format(value)
                                                                        : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data && data.length ? data.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>

        </div>
    )
}

export default Contact