import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import Emojis from "../../util/Emojis";
import { gql, useQuery } from "@apollo/client";
import { ImportContacts } from "@mui/icons-material";
import Group from "../../pages/group/Group";
import Container from "../../components/Container";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

function BulkMessage() {
    const [devices, setDevices] = useState([]);

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    //GRAPHQL DEVICE QUERY START
    const { refetch, data, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const [message, setMessage] = useState({ messageType: "template" });
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const selectedDeviceRef = useRef([]);

    const cursorRef = useRef("");

    const [isClicked, setIsClicked] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);

    const [receivers, setReceivers] = useState([])
    const [receiversPhone, setReceiversPhone] = useState([])

    const phoneRef = useRef("");
    const messageRef = useRef("");
    const dateTimeRef = useRef({});
    const fileRef = useRef("");

    //SET Remain Msg to be send
    // setTotalMessage(Number(phones.length));
    const [totalMessage, setTotalMessage] = useState(0);
    const [sentMessage, setSentMessage] = useState(0);
    const [groupVisible, setGroupVisible] = useState(false);

    //INTERNET CHECKER START
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    //INTERNET CHECKER END
    const handleContacts = (contacts) => {

        let receiversPhone = contacts.map(contacts => contacts && contacts.phone)
        setReceivers(contacts);
        setReceiversPhone(receiversPhone)
        setGroupVisible(false);
        setTotalMessage(receiversPhone.length);
        selectedDeviceRef.current = [];
    }

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const getSelectedEmoji = (selectedEmoji) => {
        // messageRef.current.value += selectedEmoji
        messageRef.current.value = messageRef.current.value.insert(cursorRef.current, ` ${selectedEmoji}`);
    }

    const sendFileMessage = async (phone, message, deviceName) => {
        let response;

        try {
            const apiURL = `https://mrkgroups.com/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;
            const formData = new FormData();

            formData.append("file", selectedFile);
            formData.append("message", message);
            formData.append("phone", phone.replace(/[^0-9]|\s+/g, "").trim());

            response = await fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            });
        } catch (error) {
            return error.message;
        }

        // Uses the 'optional chaining' operator
        if (response?.ok) {
            let result = await response.json();
            return result.message;
        } else {

            // return response?.status;
            return "Your device is offline. Please check Internet in your mobile phone or reconnect your device";
        }
    };

    const sendSimpleMessage = async (phone, message, deviceName) => {

        let apiURL = "https://mrkgroups.com/chat/sendMessage";

        let data = JSON.stringify({
            "id": userInfo.uid,
            "name": deviceName,
            "phone": phone.replace(/[^0-9]|\s+/g, "").trim(),
            "message": message
        });

        var config = {
            method: "POST",
            url: apiURL,
            headers: {
                "Content-type": "application/json",
            },
            data: data,
        };

        try {
            let response = await axios(config);
            return response.data.message;
        } catch (error) {
            if (error.response) {
                // The client was given an error response (5xx, 4xx)
                return error.response.data.message;
            } else if (error.request) {
                // The client never received a response, and the request was never left
                return error.request;
            } else {
                // Anything else
                return error.message;
            }
        }

    }

    const sendMessage = () => {

        setIsClicked(true);

        if (selectedDeviceRef.current.length === 0) {
            setError("Please select atleast one device");
            return
        }

        //CALCULATE SELECTED DEVICE

        let timesPromise = [];
        let j = 0;

        let promiseLength = Math.round(receiversPhone.length / selectedDeviceRef.current.length) === 1 ? selectedDeviceRef.current.length : Math.round(receiversPhone.length / selectedDeviceRef.current.length);

        const prepareMessage = (callBack) => {
            timesPromise = [];
            j = 0;

            for (let round = 0; round <= promiseLength; round++) {

                for (let i = 0; i < selectedDeviceRef.current.length; i++) {
                    let phone = "";
                    let deviceName = selectedDeviceRef.current[i];
                    let receiver = "";
                    receiver = receivers[j++];

                    if (deviceName && receiver) {
                        phone = receiver.phone;
                    } else {
                        break;
                    }

                    if (
                        deviceName &&
                        phone
                    ) {

                        if (!timesPromise[round])
                            timesPromise[round] = [];

                        if (message.messageType === "template") {

                            timesPromise[round].push(() => callBack(phone, `Dear ${receiver.formattedName}\n\n${message.message}\n\nThank You\n${receiver.formattedName}`, deviceName));
                        } else {
                            timesPromise[round].push(() => callBack(phone, `${message.message}`, deviceName));
                        }
                    } else {
                        break;
                    }
                }

                //RESET IF RECEIVERS NUMBERS ARE REMAIN
                if (j < receiversPhone.length) {
                    continue;
                } else {
                    break;
                }
            }
        }

        if (isSelected) {
            prepareMessage(sendFileMessage);
        } else {
            prepareMessage(sendSimpleMessage);
        }

        (function myLoop(i) {
            let interval = 100;

            setTimeout(async function () {
                // console.log(timesPromise[i], i); //  your code here 

                const response = await Promise.all(timesPromise[i].map(f => f()))

                setSentMessage(prevData => {
                    return prevData + response.length;
                });

                if (--i >= 0) {
                    if (isOnline) myLoop(i);
                } else {
                    resetAll();
                }

            }, interval)
        })(timesPromise.length - 1);

    };

    const handleSelectedDevice = (e) => {
        if (e.target.checked) {
            selectedDeviceRef.current.push(e.target.name)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.name)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)
        } else {
            setIsDeviceSelected(false)
        }
    }

    const resetAll = () => {

        setMessage({ messageType: "template" });
        setStatus("");
        setError("");
        setSelectedFile("");
        setIsSelected(false);
        setIsClicked(false);
        setIsScheduleClicked(false);
        setTotalMessage(0);
        setSentMessage(0);
        phoneRef.current.value = "";
        messageRef.current.value = "";
        dateTimeRef.current.value = {};
        fileRef.current.value = "";
    }

    useEffect(() => {
        if (
            receiversPhone &&
            receiversPhone.length > 0
        )
            phoneRef.current.value = receiversPhone.join(",");
    }, [receiversPhone])

    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line no-extend-native
    String.prototype.insert = function (index, string) {
        if (index > 0) {
            return this.substring(0, index) + string + this.substr(index);
        }

        return string + this;
    };

    const selFun = useCallback(e => {
        cursorRef.current = e.target.selectionStart;
    }, []);

    useEffect(() => {
        document.getElementById('message').addEventListener('click', selFun, false);
        document.getElementById('message').addEventListener('keydown', selFun, false);
        document.getElementById('message').addEventListener('keyup', selFun, false);

        return () => {
            document.removeEventListener("click", selFun, false);
            document.removeEventListener("keydown", selFun, false);
            document.removeEventListener("keyup", selFun, false);
        };

    }, [selFun]);

    useEffect(() => {
        if (
            error !== "" ||
            status !== ""
        ) {

            setTimeout(() => {
                resetAll()
            }, 5000);
        }
    }, [error, status]);


    return (
        <div className="flex w-screen ">
            <Sidebar />
            <Container>
                <div className="body w-full flex-1 ">
                    <div className="w-full">
                        <div className="w-full rounded-md p-2 h-screen overflow-y-scroll">
                            {!groupVisible && <div>
                                <div className="head">
                                    <p className="mb-4 rounded bg-blue-300 p-2 text-center text-md font-semibold tracking-wider text-white shadow-xl w-1/3 m-auto">
                                        SEND CAMPAIGN MESSAGE
                                    </p>
                                    <hr className="mb-2" />
                                </div>
                                <div className=" flex flex-col items-center justify-center border-2 border-gray-300 p-4 rounded-xl bg-white w-10/12 m-auto shadow-md">
                                    <h2 className="text-lg font-serif text-gray-600 text-semibold">DEVICE LIST</h2>
                                    <div className="mt-4 flex justify-start items-center space-x-2" >
                                        {devices && devices.map((device) => {
                                            return (
                                                <div className="flex justify-start items-center space-x-2" key={device.deviceName}>
                                                    <input type="checkbox" name={device.deviceName} id={device.deviceName} key={device.deviceName} onChange={handleSelectedDevice} disabled={!device.isConnected} />
                                                    <label htmlFor={device.deviceName} className="text-xl font-serif">{device.deviceName}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="body flex flex-col items-center justify-center shadow-md">
                                    <div
                                        className="flex w-10/12 flex-col space-y-4"
                                    >
                                        <div className="flex justify-start items-center space-x-2 my-2 mt-4">
                                            <label htmlFor="phone" className="text-md font-serif font-semibold text-gray-600 ">
                                                Select Any Group :
                                            </label>
                                            <button disabled={isClicked}
                                                className="text-white px-4 py-1 rounded-full bg-blue-600 flex justify-start items-center space-x-2" onClick={() => setGroupVisible(!groupVisible)}>
                                                <ImportContacts />
                                                <p>View Group</p>
                                            </button>
                                        </div>

                                        <input autoComplete="chrome-off"
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className="w-full rounded-md px-2"
                                            onChange={handleMessage}
                                            ref={phoneRef}
                                            disabled={isClicked}
                                        />

                                        <div className="flex justify-between items-center">
                                            <div>
                                                <label
                                                    htmlFor="message"
                                                    className="text-md font-serif font-semibold text-gray-600"
                                                >
                                                    Message:{" "}
                                                    <span className="text-md  text-blue-500 font-semibold">
                                                        Total : {totalMessage}
                                                    </span>{" "}
                                                    |{" "}
                                                    <span className="text-md  text-green-500 font-semibold">
                                                        Sent : {sentMessage}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="flex justify-start items-center space-x-2">
                                                <label htmlFor="template" className="flex justify-start space-x-2 items-center">
                                                    <input type="radio" name="messageType" id="messageType" value="template" onChange={handleMessage} checked={message.messageType === "template"} />
                                                    <span>Custom Message</span>
                                                </label>
                                                <label htmlFor="template" className="flex justify-start space-x-2 items-center">
                                                    <input type="radio" name="messageType" id="messageType" value="default" onChange={handleMessage} checked={message.messageType === "default"} />
                                                    <span>Simple Message</span>
                                                </label>
                                            </div>
                                        </div>
                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="10"
                                            className="w-full rounded-md p-2 shadow-md"
                                            onChange={handleMessage}
                                            ref={messageRef}
                                            placeholder="Text..."
                                            disabled={isClicked}
                                        ></textarea>

                                        <label htmlFor="file" className="text-md font-serif font-semibold text-gray-600">
                                            Upload File:
                                        </label>
                                        <input autoComplete="chrome-off"
                                            type="file"
                                            name="file"
                                            id="file"
                                            onChange={changeHandler}
                                            ref={fileRef}
                                            disabled={isClicked}
                                        />

                                        {isSelected && selectedFile ? (
                                            <div>
                                                <p>
                                                    Filename: {selectedFile.name ? selectedFile.name : ""}
                                                </p>
                                                <p>
                                                    Filetype: {selectedFile.type ? selectedFile.type : ""}
                                                </p>
                                                <p>
                                                    Size in bytes:{" "}
                                                    {selectedFile.size ? selectedFile.size : ""}
                                                </p>
                                                <p>
                                                    lastModifiedDate:{" "}
                                                    {selectedFile.lastModifiedDate
                                                        ? selectedFile.lastModifiedDate.toLocaleDateString()
                                                        : ""}
                                                </p>
                                            </div>
                                        ) : (
                                            <p></p>
                                        )}

                                        {isScheduleClicked && (
                                            <div>
                                                <label htmlFor="dateTime" className="text-md font-serif font-semibold text-gray-600 ">
                                                    Schedule DateTime:
                                                </label>
                                                <input autoComplete="chrome-off"
                                                    type="datetime-local"
                                                    name="dateTime"
                                                    id="dateTime"
                                                    className="w-full rounded-md p-2"
                                                    onChange={handleMessage}
                                                    ref={dateTimeRef}
                                                    disabled={isClicked}
                                                />
                                            </div>
                                        )}
                                        <div className="my-2">
                                            {status !== "" ? (
                                                <p className="text-center text-xl tracking-normal text-green-500 ">
                                                    {status}
                                                </p>
                                            ) : error !== "" ? (
                                                <p className="text-center text-xl tracking-normal text-red-500">
                                                    {error}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="p-2 text-right">

                                            <button
                                                className="rounded-md bg-yellow-400 py-2 px-12 text-white"
                                                onClick={sendMessage}
                                                disabled={isClicked || isScheduleClicked || !isOnline || !isDeviceSelected}
                                            >
                                                {!isDeviceSelected ? "Select device" : isClicked ? "Sending..." : "Send Now"}
                                            </button>
                                            {/* {!isScheduleClicked && (<button
                                            className="rounded-md bg-blue-400 py-2 px-12 text-white ml-4"
                                            onClick={() => setIsScheduleClicked(true)}
                                        >
                                            Schedule
                                        </button>)}
                                        {isScheduleClicked && !isScheduleProcess && (<button
                                            className="rounded-md bg-blue-400 py-2 px-12 text-white ml-4"
                                            onClick={() => sendScheduleMessage()}
                                        >
                                            Set Schedule
                                        </button>)}
                                        {isScheduleClicked && isScheduleProcess && (<button
                                            className="rounded-md bg-blue-400 py-2 px-12 text-white ml-4"
                                        >
                                            Please Wait...
                                        </button>)} */}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {groupVisible && <Group className="w-full" handleGroupContact={handleContacts} />}
                        </div>
                    </div>
                </div>
                <Emojis getSelectedEmoji={(data) => getSelectedEmoji(data)} />
            </Container>
        </div>
    );
}

export default BulkMessage;
