import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import Group from "../group/Group";
import Container from "../../components/Container";

const DEVICE_GET = gql`
    query($uid: String!){
        devicesByUID(uid: $uid) {
            id
            deviceId
            deviceName
            uid
            userId
            isConnected
            createdAt
            updatedAt
        }
}`;

function GroupManagement() {
    const [devices, setDevices] = useState([]);

    const userInfo = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };

    //GRAPHQL DEVICE QUERY START
    const { refetch, data, loading } = useQuery(DEVICE_GET, {
        variables: { uid: userInfo.uid },
    });

    const [message, setMessage] = useState({ messageType: "template" });
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const selectedDeviceRef = useRef([]);

    const cursorRef = useRef("");

    const [isClicked, setIsClicked] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);

    const [receivers, setReceivers] = useState([])
    const [receiversPhone, setReceiversPhone] = useState([])

    const phoneRef = useRef("");
    const messageRef = useRef("");
    const dateTimeRef = useRef({});
    const fileRef = useRef("");

    //SET Remain Msg to be send
    // setTotalMessage(Number(phones.length));
    const [totalMessage, setTotalMessage] = useState(0);
    const [sentMessage, setSentMessage] = useState(0);
    const [groupVisible, setGroupVisible] = useState(false);

    //INTERNET CHECKER START
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    //INTERNET CHECKER END
    const handleContacts = (contacts) => {

        let receiversPhone = contacts.map(contacts => contacts && contacts.phone)
        setReceivers(contacts);
        setReceiversPhone(receiversPhone)
        setGroupVisible(false);
        setTotalMessage(receiversPhone.length);
        selectedDeviceRef.current = [];
    }

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const getSelectedEmoji = (selectedEmoji) => {
        // messageRef.current.value += selectedEmoji
        messageRef.current.value = messageRef.current.value.insert(cursorRef.current, ` ${selectedEmoji}`);
    }

    const sendFileMessage = async (phone, message, deviceName) => {
        let response;

        try {
            const apiURL = `https://mrkgroups.com/chat/sendMessageFile/${userInfo.uid}/${deviceName}`;
            const formData = new FormData();

            formData.append("file", selectedFile);
            formData.append("message", message);
            formData.append("phone", phone.replace(/[^0-9]|\s+/g, "").trim());

            response = await fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            });
        } catch (error) {
            return error.message;
        }

        // Uses the 'optional chaining' operator
        if (response?.ok) {
            let result = await response.json();
            return result.message;
        } else {

            // return response?.status;
            return "Your device is offline. Please check Internet in your mobile phone or reconnect your device";
        }
    };

    const sendSimpleMessage = async (phone, message, deviceName) => {

        let apiURL = "https://mrkgroups.com/chat/sendMessage";

        let data = JSON.stringify({
            "id": userInfo.uid,
            "name": deviceName,
            "phone": phone.replace(/[^0-9]|\s+/g, "").trim(),
            "message": message
        });

        var config = {
            method: "POST",
            url: apiURL,
            headers: {
                "Content-type": "application/json",
            },
            data: data,
        };

        try {
            let response = await axios(config);
            return response.data.message;
        } catch (error) {
            if (error.response) {
                // The client was given an error response (5xx, 4xx)
                return error.response.data.message;
            } else if (error.request) {
                // The client never received a response, and the request was never left
                return error.request;
            } else {
                // Anything else
                return error.message;
            }
        }

    }

    const sendMessage = () => {

        setIsClicked(true);

        if (selectedDeviceRef.current.length === 0) {
            setError("Please select atleast one device");
            return
        }

        //CALCULATE SELECTED DEVICE

        let timesPromise = [];
        let j = 0;

        let promiseLength = Math.round(receiversPhone.length / selectedDeviceRef.current.length) === 1 ? selectedDeviceRef.current.length : Math.round(receiversPhone.length / selectedDeviceRef.current.length);

        const prepareMessage = (callBack) => {
            timesPromise = [];
            j = 0;

            for (let round = 0; round <= promiseLength; round++) {

                for (let i = 0; i < selectedDeviceRef.current.length; i++) {
                    let phone = "";
                    let deviceName = selectedDeviceRef.current[i];
                    let receiver = "";
                    receiver = receivers[j++];

                    if (deviceName && receiver) {
                        phone = receiver.phone;
                    } else {
                        break;
                    }

                    if (
                        deviceName &&
                        phone
                    ) {

                        if (!timesPromise[round])
                            timesPromise[round] = [];

                        if (message.messageType === "template") {

                            timesPromise[round].push(() => callBack(phone, `Dear ${receiver.formattedName}\n\n${message.message}\n\nThank You\n${receiver.formattedName}`, deviceName));
                        } else {
                            timesPromise[round].push(() => callBack(phone, `${message.message}`, deviceName));
                        }
                    } else {
                        break;
                    }
                }

                //RESET IF RECEIVERS NUMBERS ARE REMAIN
                if (j < receiversPhone.length) {
                    continue;
                } else {
                    break;
                }
            }
        }

        if (isSelected) {
            prepareMessage(sendFileMessage);
        } else {
            prepareMessage(sendSimpleMessage);
        }

        (function myLoop(i) {
            let interval = 100;

            setTimeout(async function () {
                // console.log(timesPromise[i], i); //  your code here 

                const response = await Promise.all(timesPromise[i].map(f => f()))

                setSentMessage(prevData => {
                    return prevData + response.length;
                });

                if (--i >= 0) {
                    if (isOnline) myLoop(i);
                } else {
                    resetAll();
                }

            }, interval)
        })(timesPromise.length - 1);

    };

    const handleSelectedDevice = (e) => {
        if (e.target.checked) {
            selectedDeviceRef.current.push(e.target.name)
        } else {
            selectedDeviceRef.current = selectedDeviceRef.current.filter(d => d !== e.target.name)
        }

        if (selectedDeviceRef.current.length > 0) {
            setIsDeviceSelected(true)
        } else {
            setIsDeviceSelected(false)
        }
    }

    const resetAll = () => {

        setMessage({ messageType: "template" });
        setStatus("");
        setError("");
        setSelectedFile("");
        setIsSelected(false);
        setIsClicked(false);
        setIsScheduleClicked(false);
        setTotalMessage(0);
        setSentMessage(0);
        phoneRef.current.value = "";
        messageRef.current.value = "";
        dateTimeRef.current.value = {};
        fileRef.current.value = "";
    }

    useEffect(() => {
        if (
            receiversPhone &&
            receiversPhone.length > 0
        )
            phoneRef.current.value = receiversPhone.join(",");
    }, [receiversPhone])

    //FETCH ALL ADDED DEVICES
    useEffect(() => {
        let devicesByUID = null;
        if (data) {
            devicesByUID = data.devicesByUID;
            setDevices(devicesByUID);
        }
    }, [data, loading]);

    useEffect(() => {
        if (userInfo) {
            refetch({ uid: userInfo.uid })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            error !== "" ||
            status !== ""
        ) {

            setTimeout(() => {
                resetAll()
            }, 5000);
        }
    }, [error, status]);


    return (
        <div className="flex w-screen h-screen bg-white">
            <Sidebar />
            <Container>
                <Group handleGroupContact={handleContacts} />
            </Container>
        </div>
    );
}

export default GroupManagement;
